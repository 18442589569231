import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Badge, Tab, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Check, Input, Textarea } from "../../../includes/Inputs";
import Swal from 'sweetalert2';
import {
  faPenToSquare,
  faTrashCan,
  faListUl,
  faEye,
  faPlus,
  faFileExcel,
  faFilePdf,
  faPrint,
  faMapPin,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

const Grid = () => {
  const [activeTab, setActiveTab] = useState("/home");
  const [modalClass, setModalClass] = useState("modal fade mapping");

  const removeShowClass = () => {
    setModalClass("modal fade mapping");
    const elements = document.querySelectorAll(".modal-backdrop.fade.show");
    elements.forEach((element) => {
      element.classList.remove("show");
      element.classList.remove("modal-backdrop");
      element.classList.remove("fade");
    });
    document.body.style = 'overflow';
  };

  const handleSelect = (selectedKey) => {
    setActiveTab(selectedKey);
  };
  return (
    <>
      <div className="card">
        <div className="card-header bg-gradient-header p-3 card-dash">
          <div className="row">
            <div className="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-4 d-flex align-items-center">
              <h6 className="mb-0 text-white">
                <FontAwesomeIcon icon={faListUl} />
                &nbsp; List of Surveyors
              </h6>
            </div>
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-2 mappingAlign">
              <button data-bs-toggle="modal" data-bs-target="#exampleModal">
                Mapping<sup className="badge bg-gradient-primary p-1">5</sup>
              </button>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 text-end">
              <Link
                to=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
                onClick={removeShowClass}
              >
                <FontAwesomeIcon icon={faFileExcel} /> Export XLS
              </Link>
              <Link
                to=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
                onClick={removeShowClass}
              >
                <FontAwesomeIcon icon={faFilePdf} /> PDF
              </Link>
              <Link
                to=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
                onClick={removeShowClass}
              >
                <FontAwesomeIcon icon={faPrint} /> Print
              </Link>
              <Link
                to="/admin/surveyor/create"
                className="btn bg-gradient-primary customBtn p-2 mb-0"
                onClick={removeShowClass}
              >
                <FontAwesomeIcon icon={faPlus} /> Add New Surveyor
              </Link>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <Table className="table align-items-center mb-0">
            <thead>
              <tr>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Surveyor ID
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Surveyor ID"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Name
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Name"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Nature
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Nature"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  License Type
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="License Type"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Mobile Number
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Mobile Number"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  City
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="City"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  State
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="State"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Zone
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Zone"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Country
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Country"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Product
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Product"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Created By
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Created By"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h6 className="ps-3 text-sm">ICGMS-S00001</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Vignesh</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Freelancer</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Licensed</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">9888898888</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Chennai</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Tamilnadu</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">South</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">India</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Car</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">ICGMS-C00001/Moorthy</h6>
                </td>
                <td className="align-middle">
                  <div className="d-flex gap-3 justify-content-center">
                    <div>
                      <Link
                        to="/admin/surveyor/show"
                        className="font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="View"
                      >
                        <FontAwesomeIcon icon={faEye} size="sm" />
                        &nbsp;
                        <span className="d-none d-md-inline-block">View</span>
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/admin/surveyor/edit"
                        className="font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="text-info"
                          size="sm"
                        />
                        &nbsp;
                        <span className="d-none d-md-inline-block">Edit</span>
                      </Link>
                    </div>
                    <div>
                    <Link
                        to="#!"
                        className="font-weight-bold text-xs"
                        onClick={(e) => {
                          e.preventDefault();
                          Swal.fire({
                            title: 'Enter your password',
                            input: 'password',
                            inputAttributes: {
                              autocapitalize: 'off'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'Confirm',
                            showLoaderOnConfirm: true,
                            preConfirm: (password) => {
                              console.log(`Entered password: ${password}`);
                            },
                            allowOutsideClick: () => !Swal.isLoading()
                          });
                        }}
                        data-toggle="tooltip"
                        data-original-title="Delete"
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          className="text-danger"
                          size="sm"
                        />
                        &nbsp;
                        <span className="d-none d-md-inline-block">Delete</span>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 className="ps-3 text-sm">ICGMS-S00001</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Dinesh</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">In-House</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Licensed</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">9888898888</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Chennai</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Tamilnadu</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">South</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">India</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">Car</h6>
                </td>
                <td>
                  <h6 className="ps-3 text-sm">ICGMS-C00001/Moorthy</h6>
                </td>
                <td className="align-middle">
                  <div className="d-flex gap-3 justify-content-center">
                    <div>
                      <Link
                        to="/admin/surveyor/show"
                        className="font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="View"
                        onClick={removeShowClass}
                      >
                        <FontAwesomeIcon icon={faEye} size="sm" />
                        &nbsp;
                        <span className="d-none d-md-inline-block">View</span>
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/admin/surveyor/edit"
                        className="font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                        onClick={removeShowClass}
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="text-info"
                          size="sm"
                        />
                        &nbsp;
                        <span className="d-none d-md-inline-block">Edit</span>
                      </Link>
                    </div>
                    <div>
                    <Link
                        to="#!"
                        className="font-weight-bold text-xs"
                        onClick={(e) => {
                          e.preventDefault();
                          Swal.fire({
                            title: 'Enter your password',
                            input: 'password',
                            inputAttributes: {
                              autocapitalize: 'off'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'Confirm',
                            showLoaderOnConfirm: true,
                            preConfirm: (password) => {
                              console.log(`Entered password: ${password}`);
                            },
                            allowOutsideClick: () => !Swal.isLoading()
                          });
                        }}
                        data-toggle="tooltip"
                        data-original-title="Delete"
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          className="text-danger"
                          size="sm"
                        />
                        &nbsp;
                        <span className="d-none d-md-inline-block">Delete</span>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      <div
        className="modal fade mapping"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Mapping
              </h5>
              <button
                type="button"
                className="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Nav
                justify
                variant="tabs"
                defaultActiveKey="/home"
                onSelect={handleSelect}
              >
                <Nav.Item>
                  <Nav.Link eventKey="/home">Pending</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="link-1">Mapped</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="link-2">Rejected</Nav.Link>
                </Nav.Item>
              </Nav>
              {activeTab === "/home" && (
                <div>
                  <div className="table-responsive">
                    <Table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Surveyor ID
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Surveyor ID"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Surveyor Name
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Surveyor Name"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Created By
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Created By"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Contact Person Name
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Contact Person Name"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Mobile Number
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Mobile Number"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            City
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="City"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            State
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="State"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Zone
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Zone"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Country
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Country"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Pincode
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Pincode"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h6 className="ps-3 text-sm">ICGMS-S00001</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">Vignesh Ltd</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">
                              ICGMS-C00001/Moorthy
                            </h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">Vignesh</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">9888898888</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">Chennai</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">Tamilnadu</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">South</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">India</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">600099</h6>
                          </td>
                          <td className="align-middle">
                            <div className="d-flex gap-3 justify-content-center">
                              <div>
                                <Link
                                  to="/admin/surveyor/map"
                                  className="font-weight-bold text-xs"
                                  data-toggle="tooltip"
                                  data-original-title="View"
                                  onClick={removeShowClass}
                                >
                                  <FontAwesomeIcon icon={faMapPin} size="sm" />
                                  &nbsp;
                                  <span className="d-none d-md-inline-block">
                                    Map
                                  </span>
                                </Link>
                              </div>
                              <div>
                                <Link
                                  to="/admin/surveyor/reject"
                                  className="font-weight-bold text-xs"
                                  data-toggle="tooltip"
                                  data-original-title="Edit user"
                                  onClick={removeShowClass}
                                >
                                  <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    className="text-danger"
                                    size="sm"
                                  />
                                  &nbsp;
                                  <span className="d-none d-md-inline-block">
                                    Reject
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
              {activeTab === "link-1" && (
                <div className="table-responsive">
                  <Table className="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          Surveyor ID
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="Surveyor ID"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          Name
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="Name"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          Nature
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="Nature"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          License Type
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="License Type"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          Mobile Number
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="Mobile Number"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          City
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="City"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          State
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="State"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          Zone
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="Zone"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          Country
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="Country"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          Product
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="Product"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                          Created By
                          <Input
                            type="text"
                            className="form-control p-1"
                            placeholder="Created By"
                          />
                        </th>
                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h6 className="ps-3 text-sm">ICGMS-S00001</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">Vignesh</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">Freelancer</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">Licensed</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">9888898888</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">Chennai</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">Tamilnadu</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">South</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">India</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">Car</h6>
                        </td>
                        <td>
                          <h6 className="ps-3 text-sm">ICGMS-C00001/Moorthy</h6>
                        </td>
                        <td className="align-middle">
                          <div className="d-flex gap-3 justify-content-center">
                            <div>
                              <Link
                                to="/admin/surveyor/show"
                                className="font-weight-bold text-xs"
                                data-toggle="tooltip"
                                data-original-title="View"
                                onClick={removeShowClass}
                              >
                                <FontAwesomeIcon icon={faEye} size="sm" />
                                &nbsp;
                                <span className="d-none d-md-inline-block">
                                  View
                                </span>
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
              {activeTab === "link-2" && (
                <div>
                  <div className="table-responsive">
                    <Table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Surveyor ID
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Surveyor ID"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Surveyor Name
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Surveyor Name"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Created By
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Created By"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Contact Person Name
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Contact Person Name"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Mobile Number
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Mobile Number"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            City
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="City"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            State
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="State"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Country
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Country"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                            Pincode
                            <Input
                              type="text"
                              className="form-control p-1"
                              placeholder="Pincode"
                            />
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                            Reason for Rejection
                          </th>
                          <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <h6 className="ps-3 text-sm">ICGMS-S00001</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">Vignesh Ltd</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">
                              ICGMS-C00001/Moorthy
                            </h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">Vignesh</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">9888898888</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">Chennai</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">Tamilnadu</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">India</h6>
                          </td>
                          <td>
                            <h6 className="ps-3 text-sm">600099</h6>
                          </td>
                          <td className="align-middle">
                            <h6 className="ps-3 text-sm">Testing Purpose</h6>
                          </td>
                          <td className="align-middle">
                            <div className="d-flex gap-3 justify-content-center">
                              <div>
                                <Link
                                  to="/admin/surveyor/map"
                                  className="font-weight-bold text-xs"
                                  data-toggle="tooltip"
                                  data-original-title="View"
                                  onClick={removeShowClass}
                                >
                                  <FontAwesomeIcon icon={faMapPin} size="sm" />
                                  &nbsp;
                                  <span className="d-none d-md-inline-block">
                                    Map
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Grid;
