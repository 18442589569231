import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logoct from "../resources/img/azure.png";
import { DropdownButton, Dropdown, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faXmark,
  faGaugeHigh,
  faGraduationCap,
  faCashRegister,
  faNewspaper,
  faPenRuler,
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

function Sidebar({ handleMenuClick, handleToggleSidebar, isSidebarOpen }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);

  const toggleDropdown = (dropdownType) => {
    if (dropdownType === "admin") {
      setIsAdminOpen(!isAdminOpen);
      setIsAccountOpen(false);
      setIsRegistrationOpen(false);
    } else if (dropdownType === "registration") {
      setIsAdminOpen(false);
      setIsAccountOpen(false);
      setIsRegistrationOpen(!isRegistrationOpen);
    } else if (dropdownType === "accounts") {
      setIsAdminOpen(false);
      setIsRegistrationOpen(false);
      setIsAccountOpen(!isAccountOpen);
    }
  };

  const sidebarRef = useRef();
  useEffect(() => {
    // Apply a class to the body when the sidebar is open
    if (isSidebarOpen) {
      document.body.classList.add("sidebar-opened");
    } else {
      document.body.classList.remove("sidebar-opened");
    }

    // Cleanup when the component is unmounted
    return () => {
      document.body.classList.remove("sidebar-opened");
    };
  }, [isSidebarOpen]);

  const sidebarClassName = sidebarOpen
    ? "sidenav sidebar-shadow navbar navbar-vertical navbar-expand-xs border-0 fixed-start bg-custom sidebar-open"
    : "sidenav sidebar-shadow navbar navbar-vertical navbar-expand-xs border-0 fixed-start bg-custom sidebar-closed ";

    const handleMenuSelection = (menuName) => {
      handleMenuClick(menuName);
      handleToggleSidebar();
    
      // Close the appropriate dropdown based on the clicked menu item
      if (menuName === "Dashboard") {
        setIsAdminOpen(false);
        setIsRegistrationOpen(false);
        setIsAccountOpen(false);
      } else if (menuName.startsWith("Masters")) {
        setIsAdminOpen(true);
        setIsRegistrationOpen(false);
        setIsAccountOpen(false);
      } else if (menuName.startsWith("Registrations")) {
        setIsAdminOpen(false);
        setIsRegistrationOpen(true);
        setIsAccountOpen(false);
      } else if (menuName.startsWith("Reports")) {
        setIsAdminOpen(false);
        setIsRegistrationOpen(false);
        setIsAccountOpen(false);
      } else if (menuName.startsWith("Account")) {
        setIsAdminOpen(false);
        setIsRegistrationOpen(false);
        setIsAccountOpen(true);
      } else if (menuName.startsWith("Roles")) {
        setIsAdminOpen(false);
        setIsRegistrationOpen(false);
        setIsAccountOpen(false);
      }
     else if (menuName === "") {
        setIsAdminOpen(false);
        setIsRegistrationOpen(false);
        setIsAccountOpen(false);
      }
    };
    
 const handleXmarkClick = () => {
    // Close the sidebar when the Xmark icon is clicked
    handleToggleSidebar();
    
    // Additionally, you may want to reset the state of dropdowns when the sidebar is closed
    setIsAdminOpen(false);
    setIsRegistrationOpen(false);
    setIsAccountOpen(false);
  };

  return (
    <div ref={sidebarRef}>
      <aside className={`sidenav sidebar-shadow navbar navbar-vertical navbar-expand-xs border-0 fixed-start bg-custom ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} id="sidenav-main" ref={sidebarRef}>
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <Row>
            <Col>
          <Link className="navbar-brand m-0" to="/admin/dashboard" onClick={() => handleMenuSelection("Dashboard")}>
            <FontAwesomeIcon
              icon={faEye}
              size="2xl"
              style={{ color: "#861f41" }}
              className="navbar-brand-img h-100 pe-2"
            />
            <span className="ms-1 font-weight-bold">ICGMS</span>
          </Link>
          </Col>
          <Col  className="my-auto ">
          <FontAwesomeIcon icon={faXmark} onClick={handleXmarkClick} size="xl" className="d-none tab-show ms-5"/>
          </Col>
          </Row>
        </div>
        <hr className="horizontal dark mt-0" />
        <div
          className="collapse navbar-collapse  w-auto "
          id="sidenav-collapse-main"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/admin/dashboard" onClick={() => handleMenuSelection("Dashboard")}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    icon={faGaugeHigh}
                  />
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                data-toggle="collapse"
                to="#"
                aria-expanded="false"
                aria-controls="ui-basic"
                onClick={() => toggleDropdown("admin")}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faGraduationCap} />
                </div>
                <span className="nav-link-text ms-1">Masters </span>
                <i className="fas fa-caret-down menu-arrow text-end mt-1"></i>
              </Link>
              <div
                className={isAdminOpen ? "collapse show" : "collapse"}
                id="master-menu"
              >
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item active-sub">
                    <NavLink className="nav-link text-dark" to="/admin/vehicle"  onClick={() => handleMenuSelection("Vehicle")}>
                      Vehicle
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link text-dark" to="/admin/location"  onClick={() => handleMenuSelection("Location")}>
                      Location
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link text-dark" to="/admin/product" onClick={() => handleMenuSelection("Product")}>
                      Product
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                href=""
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="ui-basic"
                onClick={() => toggleDropdown("registration")}
              >
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCashRegister} />
                </div>
                <span className="nav-link-text ms-1">Registrations </span>
                <i className="fas fa-caret-down menu-arrow text-end mt-1"></i>
              </Link>
              <div
                className={isRegistrationOpen ? "collapse show" : "collapse"}
                id="master-menu"
              >
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item active-sub">
                    <NavLink className="nav-link text-dark" to="/admin/icgms" onClick={() => handleMenuSelection("ICGMS Users")}>
                      ICGMS Users
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link text-dark" to="/admin/client" onClick={() => handleMenuSelection("Client")}>
                      Client
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link text-dark" to="/admin/surveyor" onClick={() => handleMenuSelection("Surveyor")}>
                      Surveyor
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link text-dark" to="/admin/repairer" onClick={() => handleMenuSelection("Repairer")}>
                      Repairer
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link text-dark"
                      to="/admin/insurancefinancier"
                      onClick={() => handleMenuSelection("Claim Financier")}
                    >
                      Claim Financier
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link  " to="/admin/report" onClick={() => handleMenuSelection("Reports")}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faNewspaper} />
                </div>

                <span className="nav-link-text ms-1">Reports</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <Link className="nav-link  " to="/admin/accounts">
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faGraduationCap} />
                </div>
                <span className="nav-link-text ms-1">Account </span>
                <i className="fas fa-caret-down menu-arrow text-end mt-1"></i>
              </Link>
              <div
                className={isAccountOpen ? "collapse show" : "collapse"}
                id="master-menu"
              >
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item active-sub">
                    <NavLink
                      className="nav-link active text-primary"
                      to="./client.html" onClick={() => handleMenuSelection("Billing")}
                    >
                      Billing
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link text-dark" to="./meter.html" onClick={() => handleMenuSelection("Payment")}>
                      Payment
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link  " to="/admin/roles" onClick={() => handleMenuSelection("Roles and Privileges")}>
                <div className="icon icon-shape icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faPenRuler} />
                </div>
                <span className="nav-link-text ms-1">Roles and Privileges</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
      {isSidebarOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 999,
          }}
          onClick={handleToggleSidebar}
        ></div>
      )}
    </div>
  );
}

export default Sidebar;
