import React from 'react'
import Creation from './Creation'

function Index() {
  return (
    <div>
      <Creation/>
    </div>
  )
}

export default Index