import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../slice/authSlice";
import { Container, Row, Col, Card, Form, InputGroup } from "react-bootstrap";
import { Check, Input } from "../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { resetLinkApi, resetpasswordApi } from "../api/apiPath";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [newPassword, setNewPassword] = useState('');
  const [conformPassword, setConformPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [conformPasswordError, setConformPasswordError] = useState('');
  const [userId, setUserId] = useState('');
  const [URL, setURL] = useState('');
  const [status, setStatus] = useState('');
  const [userName, setUserName] = useState('')
  const [passwordNewVisible, setPasswordNewVisible] = useState(false);
  const [passwordReVisible, setPasswordReVisible] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility1 = () => {
    setPasswordNewVisible(!passwordNewVisible);
  };

  const togglePasswordVisibility2 = () => {
    setPasswordReVisible(!passwordReVisible);
  };

  useEffect(() => {
    const fullURL = window.location.href.trim();
    setURL(fullURL);

    console.log(fullURL);

    handleURL(fullURL);
    
  }, []);

  const handleURL = async (url) => {
    try {
      const response = await resetLinkApi({ url });
      console.log(response.data.user_id);
      setUserId(response.data.user_id);
      setUserName(response.data.username);
      setStatus(response.data.status);
    } catch (error) {
      console.error('Error making API call: ', error);
    }
  };

  const passwordPattern = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,}$/;


  const handleNewPassword = (password) => {
    setNewPassword(password);

    if (password.trim() === '') {
      setNewPasswordError('Please enter the password.');
      return;
    } else if (!passwordPattern.test(password)) {
      setNewPasswordError('Password must be at least 6 characters long and include at least one alphanumeric character and one special character.');
      return;
    } else {
      setNewPasswordError('');
      return;
    }
  };

  const handleConformPassword = (password) => {
    setConformPassword(password);
    if (password.trim() === '') {
      setConformPasswordError('Please enter the password.');
      return;
    } else {
      setConformPasswordError('');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    handleNewPassword(newPassword);
    handleConformPassword(conformPassword);

    if (!passwordPattern.test(newPassword)) {
      setNewPasswordError('Password must be at least 6 characters long and include at least one alphanumeric character and one special character.');
      return;
    } 

    if (newPassword !== conformPassword) {
      setNewPasswordError('Password does not match');
      setConformPasswordError('Password does not match');
      return;
    }

    if (newPassword !== '' && conformPassword !== '' && newPassword === conformPassword) {
      setNewPassword('');
      setConformPassword('');
      setNewPasswordError('');
      setConformPasswordError('');

      // Assuming resetpasswordApi returns a Promise
      resetpasswordApi({
        conformPassword: conformPassword,
        newPassword: newPassword,
        userId: userId, // Make sure userId is set appropriately
        url: URL, // Make sure URL is set appropriately
      })
        .then((response) => {
          if (response.data.success) {
            toast.success('Password has been updated successfully.', {
              position: toast.POSITION.TOP_CENTER,
              className: 'toast-success',
            });
            setTimeout(() => {
              navigate('/');
            }, 2000);
          } else {
            // Handle API response failure if needed
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message || 'An error occurred while updating the password.', {
            position: toast.POSITION.TOP_CENTER,
            className: 'toast-error',
          });
        });
    } else {
      console.log('Validation error or missing data');
    }
  };

  return (
    <>
      <main className="main-content  mt-0">
        <section>
          <div className="page-header loginHeight">
            <Container>
              <ToastContainer />
              <Row>
                <Col
                  lg={5}
                  xl={4}
                  md={6}
                  className="d-flex flex-column mx-auto "
                >
                  {status===0 || userId === ""? (<Card className="card-plain mt-5">
                    <Card.Header className="pb-0 text-left bg-transparent">
                      <h3 className="font-weight-bolder text-info text-gradient">
                        Link Expired
                      </h3>
                    </Card.Header>
                    <Card.Body>
                      <p>Link has expired. Please generate a new link to proceed with the reset</p>
                    </Card.Body>
                  </Card>) : (
                  <Card className="card-plain mt-5">
                    <Card.Header className="pb-0 text-left bg-transparent">
                      <h3 className="font-weight-bolder text-info text-gradient">
                        Reset Password
                      </h3>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <div>
                        <label>New Password</label>
                        <Input
                          type={passwordNewVisible ? "text" : "password"}
                          className={"form-control pass"}
                          placeholder="Password"
                          label="password"
                          value={newPassword}
                          onChange={(e) => {
                            const inputText = e.target.value;
                            if (inputText.length <= 6) {
                              setNewPassword(inputText);
                            }
                          }}
                        />
                          <FontAwesomeIcon
                            icon={passwordNewVisible ? faEyeSlash : faEye}
                            className="password-toggle-icon eyePosition"
                            onClick={togglePasswordVisibility1}
                          />
                           <span className='text-danger'>{newPasswordError}</span>
                           </div>
                        <label>Re-enter Password</label>
                        <div className="password-input mb-3">
                          <Input
                            type={passwordReVisible ? "text" : "password"}
                            className={"form-control pass"}
                            placeholder="Password"
                            label="password"
                            value={conformPassword}
                            onChange={(e) => {
                              const inputText = e.target.value;
                              if (inputText.length <= 6) {
                                setConformPassword(inputText);
                              }
                            }}
                          />
                          <FontAwesomeIcon
                            icon={passwordReVisible ? faEyeSlash : faEye}
                            className="password-toggle-icon eyePosition"
                            onClick={togglePasswordVisibility2}
                          />
                          <span className='text-danger'>{conformPasswordError}</span>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn bg-gradient-primary w-100 mt-4 mb-0"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card> )}
                </Col>
                <Col lg={6}>
                  <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                    <div
                      className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                      style={{
                        backgroundImage:
                          'url("../assets/img/curved-images/curved11.jpg")',
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </main>
    </>
  );
}

export default Login;
