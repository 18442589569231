import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../slice/authSlice";
import { Container, Row, Col, Card, Form, InputGroup } from "react-bootstrap";
import { Check, Input } from "../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'js-cookie';


import { loginApi } from "../api/apiPath";
import { useEffect } from "react";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rememberMe, setRemeberMe] = useState(false);
  // const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const secretKey = process.env.SECRET_KEY;
  const validateEmail = (email) => {
    const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    const re = /^(?=.*[!@#$%^&*])[\w!@#$%^&*]{6,}$/;
    return re.test(password);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleEmailChange = (e) => {
    if (e.target.value.includes(" ")) {
      e.target.value = e.target.value.replace(/\s/g, "");
    }
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    if (e.target.value.includes(" ")) {
      e.target.value = e.target.value.replace(/\s/g, "");
    }
    if (e.target.value.length <= 6) {
      setPassword(e.target.value);
    } else {
      e.target.value = e.target.value.slice(0, 6);
    }
  };

  const handleCheckboxChange = (event) => {
    setRemeberMe(event.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (email.trim() === "" && password.trim() === "") {
      setEmailError("Please enter email");
      setPasswordError("Please enter password");
      return;
    } else {
      setEmailError("");
      setPasswordError("");
    }
  
    if (email.trim() === "") {
      setEmailError("Please enter email");
      return;
    } else {
      setEmailError("");
    }
  
    if (password.trim() === "") {
      setPasswordError("Please enter password");
      return;
    } else {
      setPasswordError("");
    }
  
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email");
      return;
    } else {
      setEmailError("");
    }
  
    if (!validatePassword(password)) {
      setPasswordError("Please enter a valid password");
      return;
    } else {
      setPasswordError("");
    }
  
    try {
      const response = await loginApi({ email, password, panel: 'admin' });
  
      if (response.data.success === false) {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          className: 'toast-error'
        });
      } else {
        if (rememberMe) {
          Cookies.set('password', password, { expires: 365 });
          Cookies.set('email', email, { expires: 365 });
          Cookies.set('user_id', response.data.id, { expires: 365 });
          Cookies.set('user_id', response.data.id, { expires: 365 });
        }
  
        Cookies.set('user_id', response.data.id);
        Cookies.set('Auth', response.data.Auth);
  
        navigate("/admin/dashboard");
      }
    } catch (error) {
      console.log(error);
    }
  
    setFormSubmitted(true);
  };
  

  useEffect(() => {
    if (Cookies.get("email") && Cookies.get("password")) {
      navigate("/admin/dashboard");
    }
  }, [navigate]);

  return (
    <>
      <main className="main-content  mt-0">
        <section>
          <div className="page-header loginHeight">
            <Container>
              <ToastContainer />
              <Row>
                <Col
                  lg={5}
                  xl={4}
                  md={6}
                  className="d-flex flex-column mx-auto "
                >
                  <Card className="card-plain mt-5 login-margin">
                    <Card.Header className="pb-0 text-left bg-transparent">
                      <h3 className="font-weight-bolder text-info text-gradient">
                        Welcome
                      </h3>
                      <p className="mb-0">
                        Enter email and password to sign in
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <div>
                          <label>Email</label>
                          <Input
                            type="email"
                            className={`form-control ${formSubmitted
                              ? validEmail && email
                                ? "is-valid has-success"
                                : "is-invalid has-danger"
                              : ""
                              }`}
                            placeholder="Email"
                            label="Email"
                            onChange={handleEmailChange}
                          />
                          <span className="text-danger small">{emailError}</span>
                        </div>
                        <div>
                          <label>Password</label>
                          <div className="password-input mb-3">
                            <Input
                              type={passwordVisible ? "text" : "password"}
                              className={`form-control pass ${formSubmitted
                                ? validPassword && password
                                  ? "is-valid has-success"
                                  : "is-invalid has-danger"
                                : ""
                                }`}
                              placeholder="Password"
                              label="password"
                              onChange={handlePasswordChange}
                              autoComplete="off"
                            />
                            <FontAwesomeIcon
                              icon={passwordVisible ? faEyeSlash : faEye}
                              className="password-toggle-icon eyePosition"
                              onClick={togglePasswordVisibility}
                            />
                            <span className="text-danger small">{passwordError}</span>
                          </div>
                        </div>
                        <div className="row mediaforget">
                          <div className="col-6 ">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="rememberMe"
                                checked={rememberMe}
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rememberMe"
                              >
                                Remember&nbsp;me
                              </label>
                            </div>
                          </div>
                          <div className="col-6 forgot text-end">
                            <Link to="/forgot">
                              <label className="form-check-label">
                                Forgot&nbsp;Password
                              </label>
                            </Link>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn bg-gradient-primary w-100 mt-4 mb-0"
                            onClick={handleSubmit}
                          >
                            Sign in
                          </button>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <div className="oblique position-absolute top-0 h-100 oblic-view d-none me-n8">
                    <div
                      className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                      style={{
                        backgroundImage:
                          'url("../assets/img/curved-images/curved11.jpg")',
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </main>
    </>
  );
}

export default Login;
