import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Row, Card } from "react-bootstrap";
import img from "../../../resources/img/azure.png";
import Select from "react-select";
import { Table, Badge, Image } from "react-bootstrap";
import Whatsapp from "../../../resources/img/whatsapp.png";
import { Check, Input, Textarea } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrashCan,
  faRotateLeft,
  faCheck,
  faUserTie,
  faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const Edit = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    Swal.fire({
      title: "Updated",
      text: "Surveyor has been updated successfully",
      icon: "success",
      timer: 5000,
      timerProgressBar: true,
      willClose: () => {
        navigate("/admin/surveyor");
      },
    });
  };
  const [selectedOption, setSelectedOption] = useState("0");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const [rows, setRows] = useState([
    {
      id: 1,
      name: "",
      designation: "",
      mobileNumber: "",
      email: "",
      contactnum: "",
      contemail: "",
      default: false,
      isDelete: false,
    },
  ]);

  const addRow = (id) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, isDelete: true };
      }
      return row;
    });
    setRows([
      ...updatedRows,
      {
        id: rows.length + 1,
        name: "",
        designation: "",
        mobileNumber: "",
        email: "",
        contactnum: "",
        contemail: "",
        default: false, // Ensure the newly added row has default as false initially
        isDelete: false,
      },
    ]);
  };

  const deleteRow = (id) => {
    const newRows = rows.filter((row) => row.id !== id);
    setRows(newRows);
  };

  const handleInputChange = (e, id, field) => {
    const { value } = e.target;
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleRadioChange = (id) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, default: !row.default }; // Toggle the current row's default value
      }
      return { ...row, default: false }; // Reset other rows to non-default
    });
    setRows(updatedRows);
  };
  return (
    <>
      <div class="mb-6 pb-6">
        <Card class="mt-4">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                  <FontAwesomeIcon icon={faUserTie} /> &nbsp;ICGMS-S00001
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                    Name <span class="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Name of the Surveyor"
                    value="Vignesh"
                    
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Nature of Surveyor </label>
                  <span class="text-danger">*</span>
                  <select
                    label="Select an option"
                    id="exampleFormControlSelect1"
                    placeholder="Choose an option"
                    className="form-control"
                    disabled="true"
                  >
                    <option value="0">Select</option>
                    <option selected value="1">
                      Freelancer
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                    FOS/Reporting Manager ID <span class="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="FOS/Reporting Manager ID"
                    value="Dinesh"
                    
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                    FOS/Reporting Manager Name{" "}
                    <span class="text-danger">*</span>
                  </label><br />
                  <Link target="_blank" to="/admin/surveyor/show" className="btn bg-gradient-primary p-1 exportbtn me-2 mb-0">FOS/Reporting Manager Name</Link>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Address</label>
                  <span class="text-danger">*</span>
                  <Textarea
                    type="type"
                    rows="1"
                    className="form-control"
                    placeholder="Address"
                    value="Kolathur, Chennai"
                    
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">City</label>
                  <span class="text-danger">*</span>
                  <Input
                    type="type"
                    className="form-control"
                    placeholder="City"
                    value="Chennai"
                    
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">State</label>
                  <span class="text-danger">*</span>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                readOnly
                              />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label class="form-control-label mb-0 whatsapp">
                        Zone <span class="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6 text-end">
                      <Check
                        type="checkbox"
                        inline={true}
                        label=""
                        id="productuser"
                        name="whatsapp"
                        
                        className="text-end ms-6 ps-6 pe-0 mb-0"
                      />
                    </div>
                  </div>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="Zone"
                                readOnly
                              />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Country</label>
                  <span class="text-danger">*</span>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                readOnly
                              />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Zipcode/Pincode</label>
                  <span class="text-danger">*</span>
                  <Input
                    type="type"
                    className="form-control"
                    placeholder="Zipcode/Pincode"
                    value="6000099"
                    
                  />
                </div>
              </div> 
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Surveyor Photo</label>
                  <span class="text-danger">*</span>
                  <Input
                    type="file"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Aadhaar Card Number <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Aadhaar Card Number"
                    value="645289652563"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Aadhaar Copy <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Aadhaar Copy"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Pan Card Number <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="ASXPS7458P"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Pan Copy <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Pan Copy"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Driving License Number
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Driving License Number"
                    value="1320120123456"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Driving License Type
                  </label>
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Driving License Number"
                    value="MC 50CC"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Driving License Validity
                  </label>
                  <span className="text-danger">*</span>
                  <Input
                    type="date"
                    className="form-control"
                    placeholder="Driving License Validity"
                    value="2028-07-22"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Driving License Copy <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Driving License Copy"
                  />
                </div>
              </div>
              <Card.Title class="card-header bg-gradient-header p-3">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <h6 class="mb-0 text-white">
                      <FontAwesomeIcon icon={faFileSignature} /> &nbsp;Contact
                      Information
                    </h6>
                  </div>
                </div>
              </Card.Title>
              <div className="table-responsive mt-2">
                <table className="table table-striped table-bordered text-center">
                  <thead>
                    <tr>
                      <td>Name <span class="text-danger">*</span></td>
                      <td>Category <span class="text-danger">*</span></td>
                      <td>Type <span class="text-danger">*</span></td>
                      <td>Licence Number <span class="text-danger">*</span></td>
                      <td>License Copy <span class="text-danger">*</span></td>
                      <td>Contact Number <span class="text-danger">*</span></td>
                      <td>Email <span class="text-danger">*</span></td>
                      <td>Geolocation <span class="text-danger">*</span></td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row) => (
                      <tr key={row.id}>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value="Moorthy"
                            
                            onChange={(e) =>
                              handleInputChange(e, row.id, "name")
                            }
                          />
                        </td>
                        <td>
                          <select
                            label="Select an option"
                            id="exampleFormControlSelect1"
                            placeholder="Choose an option"
                            className="form-control"
                            
                            onChange={(e) =>
                              handleInputChange(e, row.id, "designation")
                            }
                          >
                            <option value="0">Select</option>
                            <option selected value="1">Primary</option>
                            <option value="1">Associate</option>
                          </select>
                        </td>
                        <td>
                          <select
                            label="Select an option"
                            id="exampleFormControlSelect1"
                            placeholder="Choose an option"
                            
                            className="form-control"
                            onChange={(e) =>
                              handleInputChange(e, row.id, "mobilenumber")
                            }
                          >
                            <option value="0">Select</option>
                            <option selected value="1">Licensed</option>
                            <option value="1">Non-Licensed</option>
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="License Number"
                            
                            value="78965412365"
                            onChange={(e) =>
                              handleInputChange(e, row.id, "email")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="file"
                            className="form-control"
                            placeholder="License Number"
                            
                            value={row.contactnum}
                            onChange={(e) =>
                              handleInputChange(e, row.id, "contactnum")
                            }
                          />
                        </td>
                        <td className="inline-block">
                          <div className="row">
                            <div className="col-8">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Contact Number"
                                value="9888898888"
                                
                                onChange={(e) =>
                                  handleInputChange(e, row.id, "mobileNumber")
                                }
                              />
                            </div>
                            <div className="col-4 mt-2">
                              <Check
                                type="checkbox"
                                inline={true}
                                label=""
                                id="productuser"
                                name="whatsapp"
                                className="mb-0"
                                
                              />
                              <img className="iconSize" src={Whatsapp} />
                            </div>
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email"
                            value="moorthy@icgms.com"
                            
                            onChange={(e) =>
                              handleInputChange(e, row.id, "name")
                            }
                          />
                        </td>
                        <td>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Latitude"
                              value="220.256.32"
                              
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Longitude"
                              value="220.256.32"
                              
                            />
                          </div>
                        </td>
                        <td className="align-middle">
                          {row.isDelete ? (
                            <button
                              className="bg-transparent"
                              onClick={() => deleteRow(row.id)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className="text-danger"
                              />
                            </button>
                          ) : (
                            <button
                              className="bg-transparent text-primary"
                              onClick={() => addRow(row.id)}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 mt-2">
                <div class="form-group">
                  <label class="form-control-label">Permitted Users</label>
                  <span class="text-danger">*</span>
                  <Input
                    type="type"
                    className="form-control"
                    placeholder="Permitted Users"
                    value="15"
                    
                  />
                </div>
              </div>
              </div>
              <Card.Title class="card-header bg-gradient-header p-3">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <h6 class="mb-0 text-white">
                      <FontAwesomeIcon icon={faFileSignature} /> &nbsp;Products
                      Offered
                    </h6>
                  </div>
                </div>
              </Card.Title>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4 mt-2">
                <div class="form-group">
                  <label class="form-control-label">Select Products </label>
                  <span class="text-danger">*</span>
                  <select
                    label="Select an option"
                    id="exampleFormControlSelect1"
                    placeholder="Choose an option"
                    value={selectedOption}
                    onChange={handleSelectChange}
                    className="form-control"
                    
                  >
                    <option value="0">Select</option>
                    <option selected value="1">Car</option>
                  </select>
                </div>
              </div>
              {/* {selectedOption === "0" && <></>} */}
              {selectedOption === "0" && (
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <td>Product Category</td>
                        <td>Product Name</td>
                        <td>Product Description</td>
                        <td>Per case price</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Car</td>
                        <td>Hyundai</td>
                        <td>i20</td>
                        <td>
                          <div class="input-group mb-3">
                            <span class="input-group-text">₹</span>
                            <Input
                              type="text"
                              class="form-control"
                              value="250"
                              
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <Link to="" className="btn button-secondary-color p-2 ">
              <FontAwesomeIcon icon={faRotateLeft} /> Reset
            </Link>
            <Link onClick={handleClick} className="btn bg-gradient-primary p-2 ">
              <FontAwesomeIcon icon={faCheck} /> Update Surveyor
            </Link>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Edit;
