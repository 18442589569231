import React, { useState, useEffect,useRef } from "react";
import { Card, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faRotateLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import Cookies from "js-cookie";
import { locationcreateApi, locationdropdownApi } from "../../../api/apiPath";
import { SelectInput } from "../../../includes/Inputs";
import Grid from './Grid'

const Creation = () => {
  const [formData, setFormData] = useState({ country: "", zone: "", state: "", city: "" });
  const [formErrors, setFormErrors] = useState({});
  const [dropdownData, setDropdownData] = useState({ country: [], zone: [], state: [], city: [] });
  const user_id = Cookies.get('user_id');
  const [update,setUpdate] = useState(true);
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = async () => {
    try {
      const response = await locationdropdownApi({ user_id: user_id });
      if (response.data) {
        setDropdownData({
          country: response.data.countryOptions || [],
          zone: response.data.zoneOptions || [],
          state: response.data.stateOptions || [],
          city: response.data.cityOptions || [],
        });
      }
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    }
  };

  const handleInputChange = (field, value) => {
    let validatedValue = value;

    if (field === 'city' || field === 'state' || field === 'zone' || field === 'country') {
      validatedValue = value.replace(/[^a-zA-Z\s]/g, '').substring(0, 15);
    }
    setFormData({
      ...formData,
      [field]: validatedValue,
    });
  };

  const handleReset = () => {
    setFormData({ country: "", zone: "", state: "", city: "" });
  }

  const handleAddLocation = async () => {
    const errors = {};
    Object.keys(formData).forEach((fieldName) => {
      if (!formData[fieldName]) {
        errors[fieldName] = `Please enter or select  a ${fieldName}`;
      }
    });

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      const Data = { ...formData, user_id: user_id };
      console.log(Data); 
      const response = await locationcreateApi(Data);
      if (response.data.success) {
        setFormData({ country: "", zone: "", state: "", city: "" });
        setFormErrors({});
        Swal.fire({
          title: 'Success!',
          text: 'Location has been added successfully',
          icon: 'success',
        });
        await fetchDropdownData();
        setUpdate((prevUpdate) => !prevUpdate);
      }
      if (!response.data.success) {
        Swal.fire({
          title: 'Warning!',
          text: 'Location already exists',
          icon: 'warning',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dropDrownUpdate = useRef(fetchDropdownData);
  return (
    <>
      <div className="mb-4">
        <Card className="mt-4">
          <Card.Title className="card-header bg-gradient-header p-3 card-dash">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <h6 className="mb-0 text-white">
                  <FontAwesomeIcon icon={faLocationDot} /> &nbsp;Create Location
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body className="card-body p-3">
            <Row className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Country</label>
                  <span className="text-danger"> *</span>
                  <SelectInput
                    placeholder="Country"
                    datalistId="countryList"
                    value={formData.country}
                    onChange={(e) => handleInputChange("country", e.target.value)}
                    options={dropdownData.country}
                  />
                  <span className="text-danger small">{formErrors.country}</span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Zone</label>
                  <span className="text-danger"> *</span>
                  <SelectInput
                    placeholder="Zone"
                    datalistId="zoneList"
                    value={formData.zone}
                    onChange={(e) => handleInputChange("zone", e.target.value)}
                    options={dropdownData.zone}
                  />
                  <span className="text-danger small">{formErrors.zone}</span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">State</label>
                  <span className="text-danger"> *</span>
                  <SelectInput
                    placeholder="State"
                    datalistId="stateList"
                    value={formData.state}
                    onChange={(e) => handleInputChange("state", e.target.value)}
                    options={dropdownData.state}
                  />
                  <span className="text-danger small">{formErrors.state}</span>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">City</label>
                  <span className="text-danger"> *</span>
                  <SelectInput
                    placeholder="City"
                    datalistId="cityList"
                    value={formData.city}
                    onChange={(e) => handleInputChange("city", e.target.value)}
                    options={dropdownData.city}
                  />
                  <span className="text-danger small">{formErrors.city}</span>
                </div>
              </div>
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <button onClick={handleReset} className="btn button-secondary-color p-2 ">
              <FontAwesomeIcon icon={faRotateLeft} /> Reset
            </button>
            <button onClick={handleAddLocation} className="btn bg-gradient-primary p-2 ">
              <FontAwesomeIcon icon={faPlus} /> Add Location
            </button>
          </div>
        </Card>
      </div>
      <Grid update={update} dropDrownUpdate={dropDrownUpdate}/>
    </>
  );
};

export default Creation;
