import React, { useState, useEffect } from "react";
import { Button, Container, Row, Card, InputGroup, Form } from "react-bootstrap";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "../../../includes/Inputs";
import {
  faArrowUp,
  faArrowLeft,
  faCube,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import Swal from 'sweetalert2';

import { productupdateApi } from "../../../api/apiPath";

const Creation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user_id = Cookies.get('user_id');

  const initialFormData = location.state.data || {
    category: "",
    description: "",
    name: "",
    price_for_client: "",
    price_for_surveyor: "",
  };
  const [formData, setFormData] = useState(initialFormData);

  const [errors, setErrors] = useState({
    category: "",
    description: "",
    name: "",
    price_for_client: "",
    price_for_surveyor: ""
  });

  useEffect(() => {
    // Clear errors when component mounts
    setErrors({});
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const hasChanges =
    formData.category !== initialFormData.category ||
    formData.name !== initialFormData.name ||
    formData.description !== initialFormData.description ||
    formData.price_for_client !== initialFormData.price_for_client ||
    formData.price_for_surveyor !== initialFormData.price_for_surveyor;

  const handleUpdate = async (e) => {
    e.preventDefault();

    let hasErrors = false;
    const newErrors = {};

    // Check for empty fields
    if (!formData.category) {
      newErrors.category = "Please enter category .";
      hasErrors = true;
    }
    if (!formData.name) {
      newErrors.name = "Please enter name.";
      hasErrors = true;
    }
    if (!formData.description) {
      newErrors.description = "Please enter description.";
      hasErrors = true;
    }
    if (!formData.price_for_client) {
      newErrors.price_for_client = "Please enter per case price for client.";
      hasErrors = true;
    }
    if (!formData.price_for_surveyor) {
      newErrors.price_for_surveyor = "Please enter per case serveyor price.";
      hasErrors = true;
    }

    // Update the state with new errors
    setErrors(newErrors);

    // Check if there are any errors
    if (hasErrors) {
      console.log(newErrors);
      return; // Prevent further execution if any field is empty
    }

    // Reset errors state
    setErrors({});
    console.log(formData);
    const data = { ...formData, user_id: user_id };
    const response = await productupdateApi(data);

    if (response.data.success) {
      Swal.fire({
        title: 'Success!',
        text: 'product has been updated successfully',
        icon: 'success',
      });
    } else {
      Swal.fire({
        title: 'Warning',
        text: response.data.message,
        icon: 'warning',
      });
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/admin/product');
  }

  const handleKeyPressCategory = (event) => {
    const pressedKey = event.key;
    if (!/[a-zA-Z\s]/.test(pressedKey) || event.target.value.length >= 18) {
      event.preventDefault();
    }
  };
  const handleKeyPressName = (event) => {
    const pressedKey = event.key;
    if (!/[a-zA-Z\s]/.test(pressedKey) || event.target.value.length >= 25) {
      event.preventDefault();
    }
  };
  const handleKeyPressDescription = (event) => {
    const pressedKey = event.key;
    if (event.target.value.length >= 180) {
      event.preventDefault();
    }
  }

  const handleKeyPressPrice = (event) => {
    const inputValue = event.target.value + event.key;
    const numericRegex = /^[0-9]+(\.[0-9]{0,2})?$/;

    if (!numericRegex.test(inputValue) || inputValue.length > 7) {
      event.preventDefault();
    }
  }

  return (
    <>
      <div class="mb-4">
        <Card class="mt-4">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                  <FontAwesomeIcon icon={faCube} /> &nbsp;Product
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Product Category</label>
                  <Form.Control
                    type="text"
                    className={`form-control ${errors.category && 'is-invalid'}`}
                    placeholder="Cubic Capacity"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPressCategory}
                  />
                  {errors.category && <span className="text-danger small">{errors.category}</span>}
                </div>

              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Product Name</label>
                  <Input
                    type="text"
                    className={`form-control ${errors.name && 'is-invalid'}`}
                    placeholder="Cubic Capacity"
                    name="name"
                    value={formData.name}
                    onKeyPress={handleKeyPressName}
                    onChange={handleInputChange} />
                  {errors.name && <span className="text-danger small">{errors.name}</span>}
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Product Description</label>
                  <Input
                    type="text"
                    className={`form-control ${errors.description && 'is-invalid'}`}
                    name="description"
                    placeholder="Cubic Capacity "
                    value={formData.description}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPressDescription} />
                  {errors.description && <span className="text-danger small">{errors.description}</span>}
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <label class="form-control-label">Per case price for client</label>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">₹</InputGroup.Text>
                  <Form.Control
                    placeholder="Price"
                    aria-label="Username"
                    className={`form-control ${errors.price_for_client && 'is-invalid'}`}
                    name="price_for_client"
                    value={formData.price_for_client}
                    onKeyPress={handleKeyPressPrice}
                    onChange={handleInputChange}
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                {errors.price_for_client && <span className="text-danger small">{errors.price_for_client}</span>}
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <label class="form-control-label">Per case serveyor price</label>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">₹</InputGroup.Text>
                  <Form.Control
                    placeholder="Price"
                    aria-label="Username"
                    className={`form-control ${errors.price_for_surveyor && 'is-invalid'}`}
                    name="price_for_surveyor"
                    value={formData.price_for_surveyor}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPressPrice}
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                {errors.price_for_surveyor && <span className="text-danger small">{errors.price_for_surveyor}</span>}
              </div>
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <a className="btn bg-gradient-primary p-2" onClick={handleBack}>
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </a>
            <button
              className="btn bg-gradient-primary p-2"
              onClick={handleUpdate}
              disabled={!hasChanges}
            >
              <FontAwesomeIcon icon={faArrowUp} /> Update
            </button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Creation;