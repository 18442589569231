import axios from "axios";
// import Cookies from 'js-cookie';


export const API = window.location.origin.replace(/:\d+$/, '') + ":3003";


export const loginApi = async (data) => axios.post(`${API}/login`, data);
export const forgotPasswordApi = async (data) => axios.post(`${API}/forgotpassword`, data);
export const resetLinkApi = async (data) => axios.post(`${API}/resetLink`, data);
export const resetpasswordApi = async (data) => axios.post(`${API}/resetpassword`, data);
export const locationcreateApi = async (data) => axios.post(`${API}/locationcreate`, data);
export const locationdropdownApi = async (data) => axios.post(`${API}/locationdropdown`,data);
export const locationgridApi = async (data) => axios.post(`${API}/locationgrid`,data);
export const locationupdateApi = async (data) => axios.post(`${API}/locationupdate`,data);
export const locationdeleteApi = async (data) => axios.post(`${API}/locationdelete`,data);
export const productcreateApi = async (data) => axios.post(`${API}/productcreate`,data);
export const productdropdownApi = async (data) => axios.post(`${API}/productdropdown`,data);
export const productdropdgridApi = async (data) => axios.post(`${API}/productgrid`,data);
export const productupdateApi = async (data) => axios.post(`${API}/productupdate`,data);
export const productdeleteApi = async (data) => axios.post(`${API}/productdelete`,data);

export const vehicalDetailsListApi = async (data) =>  axios.post(`${API}/vehicaldetailslist`,data,
// {
//     headers: {
//       'Authorization': `Bearer ${Cookies.get('Auth')}`
//     }
//   }
);
export const addVehicalDetailsApi = async (data) =>  axios.post(`${API}/addvehicaldetails`,data,
// {
//     headers: {
//       'Authorization': `Bearer ${Cookies.get('Auth')}`
//     }
//   }
);
export const updateVehicalDetailsApi = async (data) =>  axios.post(`${API}/updatevehicledetails`,data,
// {
//     headers: {
//       'Authorization': `Bearer ${Cookies.get('Auth')}`
//     }
//   }
);
export const deleteVehicleApi = async (data) =>  axios.post(`${API}/deleteVehicle`,data,
// {
//     headers: {
//       'Authorization': `Bearer ${Cookies.get('Auth')}`
//     }
//   }
);
export const vehicalImageApi = async (data) =>  axios.post(`${API}/vehicalimage`,data,
// {
//     headers: {
//       'Authorization': `Bearer ${Cookies.get('Auth')}`
//     }
//   }
);

