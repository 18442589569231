import React from "react";
import { Button, Container, Row, Card } from "react-bootstrap";
import Select from "react-select";
import { Input } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotateLeft,
  faArrowLeft,
  faLocationDot,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

const Creation = () => {
  const country = [
    { value: "india", label: "India" },
    { value: "america", label: "America" },
    { value: "england", label: "England" },
  ];
  const zone = [
    { value: "east", label: "East" },
    { value: "north", label: "North" },
    { value: "south", label: "South" },
  ];
  const state = [
    { value: "tamilnadu", label: "Tamilnadu" },
    { value: "kerala", label: "Kerala" },
    { value: "karnataka", label: "Karnataka" },
  ];
  const city = [
    { value: "chennai", label: "Chennai" },
    { value: "coimbatore", label: "Coimbatore" },
    { value: "madurai", label: "Madurai" },
  ];
  return (
    <>
      <div class="mb-4">
        <Card class="mt-4">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                  <FontAwesomeIcon icon={faLocationDot} /> &nbsp;Loction
                </h6>
              </div>
              <div className="col-6 d-flex gap-3 justify-content-end pe-2">
                <a
                  href="/admin/location/edit"
                  className="btn bg-gradient-primary p-2 mb-0"
                >
                  <FontAwesomeIcon icon={faPenToSquare} /> Edit
                </a>
                <a
                  href="/admin/location"
                  className="btn bg-gradient-primary p-2 mb-0"
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Back
                </a>
              </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Country</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="India"
                    disabled="disabled"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Zone</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="South"
                    disabled="disabled"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">State</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="TamilNadu"
                    disabled="disabled"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">City</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Chennai"
                    disabled="disabled"
                  />
                </div>
              </div>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Creation;
