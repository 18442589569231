import React, { useState } from "react";
import { Button, Container, Row, Card } from "react-bootstrap";
import Select from "react-select";
import { Check, Input } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotateLeft,
  faArrowUp,
  faMotorcycle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";

const Creation = () => {

  return (
    <>
      <div class="mb-6">
        <Card class="mt-4">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                  <FontAwesomeIcon icon={faMotorcycle} /> &nbsp;Vehicle
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Vehicle Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Vehicle Name"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Class</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="class"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">End use</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="End use"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Name of manufacturer</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Manufacturer"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Make</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Make"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Model</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Model"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Variant</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Variant"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Seating capacity</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Seating capacity"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">HP</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="HP"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Cubic Capacity </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Cubic capacity"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Fuel used</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Fuel used"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                    Registered Laden Weight
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Registered laden weight"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Unladen Weight</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Unladen weight"
                  />
                </div>
              </div>
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <a
              href="/admin/vehicle"
              className="btn bg-gradient-primary p-2"
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </a>
            <a href="/admin/vehicle" className="btn bg-gradient-primary p-2 ">
              <FontAwesomeIcon icon={faArrowUp} /> Update
            </a>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Creation;
