import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Row, Card } from "react-bootstrap";
import img from "../../../resources/img/azure.png";
import Select from "react-select";
import { Table, Badge, Image } from "react-bootstrap";
import { Check, Input, Textarea, SelectInput } from "../../../includes/Inputs";
import Whatsapp from "../../../resources/img/whatsapp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faFileSignature,
  faRotateLeft,
  faCheck,
  faUserTie,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const Creation = () => {
  
  const [licenseNumber, setLicenseNumber] = useState('');
  const [passportNumber, setPassportNumber] = useState('');

  const navigate = useNavigate();
  const handleClick = () => {
    Swal.fire({
      title: "CA-001",
      text: "User has been created successfully",
      icon: "success",
      timer: 5000,
      timerProgressBar: true,
      willClose: () => {
        navigate("/admin/icgms");
      },
    });
  };

  const genderS = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];
  const userOptions = [
    { label: "Admin user", value: "admin" },
    { label: "Product vertical user", value: "product" },
  ];
  const designationList = [
    { value: "admin", label: "Admin" },
    { value: "it", label: "IT" },
    { value: "finance", label: "Finance  Account" },
  ];
  const countryS = [
    { value: "india", label: "India" },
    { value: "america", label: "America" },
    { value: "england", label: "England" },
  ];
  const zoneS = [
    { value: "east", label: "East" },
    { value: "north", label: "North" },
    { value: "south", label: "South" },
  ];
  const stateS = [
    { value: "tamilnadu", label: "Tamilnadu" },
    { value: "kerala", label: "Kerala" },
    { value: "karnataka", label: "Karnataka" },
  ];
  const cityS = [
    { value: "chennai", label: "Chennai" },
    { value: "coimbatore", label: "Coimbatore" },
    { value: "madurai", label: "Madurai" },
  ];
  const bloodGroup = [
    { value: "O-", label: "O-" },
    { value: "B+", label: "B+" },
    { value: "O+", label: "O+" },
    { value: "B-", label: "B-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
  ];
  const licenseType = [
    { value: "MC 50CC", label: "MC 50CC" },
    { value: "MCWOG/FVG", label: "MCWOG/FVG" },
    { value: "LMV", label: "LMV" },
    { value: "LMV-TR", label: "LMV-TR" },
  ];
  const [sameAsHome, setSameAsHome] = useState(false);
  const [firstSetLatitude, setFirstSetLatitude] = useState("");
  const [firstSetLongitude, setFirstSetLongitude] = useState("");

  const handleCheckboxChange = (e) => {
    setSameAsHome(e.target.checked);
  };

  const handleFirstLatitudeChange = (e) => {
    setFirstSetLatitude(e.target.value);
  };

  const handleFirstLongitudeChange = (e) => {
    setFirstSetLongitude(e.target.value);
  };
  const [surveyChecked, setSurveyChecked] = useState(false);

  const handlesurveyChange = (e) => {
    setSurveyChecked(e.target.checked);
  };

  const [licensedType, setLicensedType] = useState("");
  const [showLicense, setShowlicense] = useState(false);

  const handleLicensedTypeChange = (e) => {
    setLicensedType(e.target.value);
    setShowlicense(e.target.value === "1");
  };
  const [selectedOption, setSelectedOption] = useState("0");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    <>
      <div className="mb-6 pb-6">
        <Card className="">
          <Card.Title className="card-header bg-gradient-header p-3 card-dash">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <h6 className="mb-0 text-white">
                  <FontAwesomeIcon icon={faUserTie} /> &nbsp;User Registration
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body className="card-body p-3">
            <Row className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Employee ID</label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Employee ID"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">First Name</label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Last Name</label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Designation</label>{" "}
                  <span className="text-danger">*</span>
                  <SelectInput
            placeholder="Designation"
            datalistId="designationList"
            options={[
              { value: "Admin" },
              { value: "User" },
            ]}
          />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Department</label>{" "}
                  <span className="text-danger">*</span>
                  <SelectInput
            placeholder="Department"
            datalistId="departmentList"
            options={[
              { value: "Admin" },
              { value: "User" },
            ]}
          />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Branch Name</label>{" "}
                  <span className="text-danger">*</span>
                  <SelectInput
            placeholder="Branch Name"
            datalistId="branchList"
            options={[
              { value: "Admin" },
              { value: "User" },
            ]}
          />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">City</label>{" "}
                  <span className="text-danger">*</span>
                  <Select
                    label="Select an option"
                    id="userselection"
                    placeholder="Choose an option"
                    options={cityS}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">State</label>{" "}
                  <span className="text-danger">*</span>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                readOnly
                              />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Zone</label>{" "}
                  <span className="text-danger">*</span>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="Zone"
                                readOnly
                              />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Country</label>{" "}
                  <span className="text-danger">*</span>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                readOnly
                              />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group mb-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="form-control-label mb-0">
                      Geolocation for Servicing Area{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="productuser"
                        name="whatsapp"
                        className="form-check-input"
                        onChange={handleCheckboxChange}
                      />
                      <label
                        htmlFor="productuser"
                        className="form-check-label ms-2 mb-0"
                      >
                        <FontAwesomeIcon icon={faHouse} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Latitude"
                    value={firstSetLatitude}
                    onChange={handleFirstLatitudeChange}
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Longitude"
                    value={firstSetLongitude}
                    onChange={handleFirstLongitudeChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Reporting Manager Employee ID
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Reporting Manager Employee ID"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Reporting Manager Name
                  </label><br />
                  <Link target="_blank" to="/admin/icgms/show" className="btn bg-gradient-primary p-1 exportbtn me-2 mb-0">Reporting Manager Name</Link>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Official Email</label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Official Email"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <div className="form-group mb-1">
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="form-control-label mb-0">
                        Official Phone Number{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="productuser"
                          name="whatsapp"
                          className="form-check-input"
                        />
                        <label
                          for="productuser"
                          className="form-check-label ms-2 mb-0"
                        >
                          <img className="iconSize" src={Whatsapp} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Official Phone Number"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Whatsapp Number</label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Whatsapp Number"
                  />
                </div>
              </div>
              <Card.Title className="card-header bg-gradient-header p-3 mb-2">
                <div className="row">
                  <div className="col-6 d-flex align-items-center">
                    <h6 className="mb-0 text-white">
                      <FontAwesomeIcon icon={faUserTie} /> &nbsp;Personal
                      Details
                    </h6>
                  </div>
                </div>
              </Card.Title>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Personal Email</label>{" "}
                  <span className="text-danger">*</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Personal Email"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <div className="form-group mb-1">
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="form-control-label mb-0">
                        Personal Phone Number 1
                        <span className="text-danger">*</span>
                      </label>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="productuser"
                          name="whatsapp"
                          className="form-check-input"
                        />
                        <label
                          for="productuser"
                          className="form-check-label ms-2 mb-0"
                        >
                          <img className="iconSize" src={Whatsapp} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Personal Phone Number 1"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <div className="form-group mb-1">
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="form-control-label mb-0">
                      Personal Phone Number 2
                        <span className="text-danger">*</span>
                      </label>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          id="productuser"
                          name="whatsapp"
                          className="form-check-input"
                        />
                        <label
                          for="productuser"
                          className="form-check-label ms-2 mb-0"
                        >
                          <img className="iconSize" src={Whatsapp} />
                        </label>
                      </div>
                    </div>
                  </div>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Personal Phone Number 2"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Blood Group</label>{" "}
                  <span className="text-danger">*</span>
                  <Select
                    label="Select an option"
                    placeholder="Choose an option"
                    value={bloodGroup}
                    options={bloodGroup}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Gender</label>{" "}
                  <span className="text-danger">*</span>
                  <select
                    label="Select an option"
                    id="exampleFormControlSelect1"
                    placeholder="Choose an option"
                    className="form-control"
                  >
                    <option value="0">Select</option>
                    <option value="1">Male</option>
                    <option value="1">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Address <span className="text-danger">*</span>
                  </label>
                  <Textarea
                    type="text"
                    rows="1"
                    className="form-control"
                    placeholder="Address"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">City</label>{" "}
                  <span className="text-danger">*</span>
                  <Select
                    label="Select an option"
                    id="userselection"
                    placeholder="Choose an option"
                    options={cityS} 
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">State</label>{" "}
                  <span className="text-danger">*</span>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                readOnly
                              />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Country</label>{" "}
                  <span className="text-danger">*</span>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                readOnly
                              />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                   Zipcode/Pincode<span className="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Zipcode/Pincode"
                  />
                </div>
              </div>
              {!sameAsHome && (
                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group mb-1">
                    <label className="form-control-label mb-0">
                      Geolocation for Resident{" "}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Latitude"
                      value={firstSetLatitude}
                      disabled={sameAsHome}
                    />
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Longitude"
                      value={firstSetLongitude}
                      disabled={sameAsHome}
                    />
                  </div>
                </div>
              )}
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                  Employee Photo
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Aadhaar Card Number <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Aadhaar Card Number"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Aadhaar Copy <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Country"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Pan Card Number <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Pan Card Number"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Pan Copy <span className="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Country"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Passport Number</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Passport Number"
                    value={passportNumber}
            onChange={(e) => setPassportNumber(e.target.value)}
                  />
                </div>
              </div>
              {passportNumber && (
              <>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Passport Validity<span className="text-danger">*</span>
                  </label>
                  <Input
                    type="date"
                    className="form-control"
                    placeholder="Country"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Passport Copy<span className="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Country"
                  />
                </div>
              </div>
              </>)}
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <div className="form-group">
          <label className="form-control-label">Driving License Number</label>
          <input
            type="text"
            className="form-control"
            placeholder="Driving License Number"
            value={licenseNumber}
            onChange={(e) => setLicenseNumber(e.target.value)}
          />
        </div>
      </div>
      {licenseNumber && (
        <>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-control-label">Driving License Type</label>
              <span className="text-danger">*</span>
              <select
                className="form-control"
                placeholder="Choose an option"
              >
                {/* Options for the select element */}
              </select>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-control-label">Driving License Validity</label>
              <span className="text-danger">*</span>
              <input
                type="date"
                className="form-control"
                placeholder="Country"
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="form-control-label">Driving License Copy <span className="text-danger">*</span></label>
              <input
                type="file"
                className="form-control"
                placeholder="Country"
              />
            </div>
          </div>
        </>
      )}
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Role <span className="text-danger">*</span>
                  </label>
                  <Select
                    label="Select an option"
                    id="exampleFormControlSelect1"
                    placeholder="Choose an option"
                  >
                    <option value="1">Role 1</option>
                    <option value="2">Role 2</option>
                  </Select>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Zone <span className="text-danger">*</span>
                  </label>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="Zone"
                                readOnly
                              />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">
                    Branch <span className="text-danger">*</span>
                  </label>
                  <Select
                    label="Select an option"
                    id="exampleFormControlSelect1"
                    placeholder="Choose an option"
                  >
                    <option value="1">option 1</option>
                    <option value="2">option 2</option>
                  </Select>
                </div>
              </div>
            </Row>
            <div className="form-check">
              <input
                type="checkbox"
                id="productuser"
                name="whatsapp"
                className="form-check-input"
                onChange={handlesurveyChange}
              />
              <label for="productuser" className="form-check-label ms-2 mb-0">
                Whether this user belongs with Surveyor role
              </label>
            </div>
            <Row>
              {surveyChecked && (
                <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label className="form-control-label">
                      Surveyor License Type{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      label="Select an option"
                      id="exampleFormControlSelect1"
                      placeholder="Choose an option"
                      onChange={handleLicensedTypeChange}
                      value={licensedType}
                      className="form-control"
                    >
                      <option value="">Select an option</option>
                      <option value="1">Licensed</option>
                      <option value="2">Non-Licensed</option>
                    </select>
                  </div>
                </div>
              )}
              {showLicense && (
                <>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Surveyor License Number{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Surveyor License Number"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div className="form-group">
                      <label className="form-control-label">
                        Surveyor License Copy{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <Input
                        type="file"
                        className="form-control"
                        placeholder="Surveyor License Number"
                      />
                    </div>
                  </div>
                </>
              )}
              {surveyChecked && (
                <>
                  <Card.Title class="card-header bg-gradient-header p-3">
                    <div class="row">
                      <div class="col-6 d-flex align-items-center">
                        <h6 class="mb-0 text-white">
                          <FontAwesomeIcon icon={faFileSignature} />{" "}
                          &nbsp;Products Offered
                        </h6>
                      </div>
                    </div>
                  </Card.Title>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-control-label">Select Product </label>
                      <span class="text-danger">*</span>
                      <select
                        label="Select an option"
                        id="exampleFormControlSelect1"
                        placeholder="Choose an option"
                        value={selectedOption}
                        onChange={handleSelectChange}
                        className="form-control"
                      >
                        <option value="0">Select</option>
                        <option value="1">Car</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
              {selectedOption === "0" && <></>}
              {selectedOption === "1" && (
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <td>Product Category</td>
                        <td>Product Name</td>
                        <td>Product Description</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Car</td>
                        <td>Hyundai</td>
                        <td>i20</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <button className="btn button-secondary-color p-2 ">
              <FontAwesomeIcon icon={faRotateLeft} /> Reset
            </button>
            <button
              onClick={handleClick}
              className="btn bg-gradient-primary p-2 "
            >
              <FontAwesomeIcon icon={faCheck} /> Add User
            </button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Creation;
