import React from 'react'
import { Card, Col } from 'react-bootstrap'

function Dashboard() {
  return (
    <>
    <div className="col-lg-8 mx-auto">
    <Card className='mt-5 bg-gradient-primary border-card'>
      <p className='fs-5 text-center pt-2 text-white'>Exciting new features are coming soon in the future </p>
    </Card>
    </div>
    </>
  );
}

export default Dashboard
