import React, { useState,useEffect,useRef } from "react";
import {
  Button,
  Container,
  Row,
  Card,
  InputGroup,
  Form,
} from "react-bootstrap";
import Select from "react-select";
import Grid from './Grid';
import { Check, Input, SelectInput, Textarea } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotateLeft,
  faPlus,
  faCube,
} from "@fortawesome/free-solid-svg-icons";
import { productcreateApi } from "../../../api/apiPath";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { productdropdownApi } from "../../../api/apiPath";
const Creation = () => {
  const [formData, setFormData] = useState({ category: "", name: "", description: "", clientPrice: "", surveyorPrice: "", });
  const [dropdownData, setDropdownData] = useState({ category: [], name: []});
  const [errors, setErrors] = useState({ category: "", name: "", description: "", clientrice: "", surveyorPrice: "", });
  const user_id = Cookies.get('user_id');
  const [update,setUpdate] = useState(true);
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = async () => {
    try {
      const response = await productdropdownApi({ user_id: user_id });
      if (response.data) {
        setDropdownData({
          category: response.data.categoryOptions || [],
          name: response.data.nameOption || [],
        });
      }
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
    }
  };
  function camelCaseToSpace(str) {
    // Add space before the capital letters and convert to lowercase
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  }
  const handleClick = async () => {
    function camelCaseToSpace(str) {
      return str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
    }
    
    const requiredFields = ["category", "name", "description", "clientPrice", "surveyorPrice"];
    const newErrors = {};
    
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        const fieldNameWithSpaces = camelCaseToSpace(field);
        const formattedFieldName = fieldNameWithSpaces.charAt(0) + fieldNameWithSpaces.slice(1);
        newErrors[field] = `Please enter ${formattedFieldName}`;
      }
    });
    // If there are errors, set them in the state and return
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const data = {...formData,user_id:user_id}
    console.log(data);
    const response = await productcreateApi(data);
    if(response.data.success){
      Swal.fire({
        title: 'Success!',
        text: 'Product has been added successfully',
        icon: 'success',
      });
      fetchDropdownData();
      setFormData({ category: "", name: "", description: "", clientPrice: "", surveyorPrice: "" });
      setUpdate((prevUpdate) => !prevUpdate);
    }
    if (!response.data.success) {
      Swal.fire({
        title: 'Warning!',
        text: 'product already exists',
        icon: 'warning',
      });
    }
  };

  const handleChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });

    // Clear the error message when the user starts typing in the field
    setErrors({
      ...errors,
      [fieldName]: "",
    });
  };

  const handleKeyPressCategory = (event) => {
    const pressedKey = event.key;
    if (!/[a-zA-Z\s]/.test(pressedKey) || event.target.value.length >= 18) {
      event.preventDefault();
    }
  };
  const handleKeyPressName = (event) => {
    const pressedKey = event.key;
    if (!/[a-zA-Z\s]/.test(pressedKey) || event.target.value.length >= 25) {
      event.preventDefault();
    }
  };
  const handleKeyPressDescription = (event) => {
    const pressedKey = event.key;
    if (event.target.value.length >= 180) {
      event.preventDefault();
    }
  }

  const handleKeyPressPrice = (event) => {
    const inputValue = event.target.value + event.key;
    const numericRegex = /^[0-9]+(\.[0-9]{0,2})?$/;
  
    if (!numericRegex.test(inputValue) || inputValue.length > 7) {
      event.preventDefault();
    }
  }

  const handleReset = (e) => {
    e.preventDefault();
    setFormData({ category: "", name: "", description: "", clientPrice: "", surveyorPrice: "" });
  }
  const dropDrownUpdate = useRef(fetchDropdownData);
  return (
    <>
      <div className="mb-4">
        <Card className="mt-4">
          <Card.Title className="card-header bg-gradient-header p-3 card-dash">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <h6 className="mb-0 text-white">
                  <FontAwesomeIcon icon={faCube} /> &nbsp;Create Product
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body className="card-body p-3">
            <Row className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Category</label>
                  <span className="text-danger"> *</span>
                  <SelectInput
                    placeholder="Category"
                    datalistId="CategoryList"
                    value={formData.category}
                    onKeyPress={handleKeyPressCategory}
                    onChange={(e) => handleChange("category", e.target.value)}
                    options={dropdownData.category}
                  />
                  {errors.category && (
                    <div className="text-danger small">{errors.category}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Name</label>
                  <span className="text-danger"> *</span>
                  <SelectInput
                    placeholder="Name"
                    datalistId="nameList"
                    value={formData.name}
                    onKeyPress={handleKeyPressName}
                    onChange={(e) => handleChange("name", e.target.value)}
                    options={dropdownData.name}
                  />
                  {errors.name && (
                    <div className="text-danger small">{errors.name}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Description</label>
                  <span className="text-danger"> *</span>
                  <Textarea
                    type="text"
                    rows="1"
                    className="form-control"
                    placeholder="Description"
                    value={formData.description}
                    onKeyPress = {handleKeyPressDescription}
                    onChange={(e) => handleChange("description", e.target.value)}
                  />
                  {errors.description && (
                    <div className="text-danger small">{errors.description}</div> 
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <label className="form-control-label">
                  Per case price for client
                </label>
                <span className="text-danger"> *</span>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">₹</InputGroup.Text>
                  <Form.Control
                    placeholder="Price"
                    value={formData.clientPrice}
                    onKeyPress={handleKeyPressPrice}
                    onChange={(e) =>
                      handleChange("clientPrice", e.target.value)
                    }
                  />
                </InputGroup>
                {errors.clientPrice && (
                    <div className="text-danger small">{errors.clientPrice}</div>
                  )}
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <label className="form-control-label">
                  Per case price for surveyor
                </label>
                <span className="text-danger"> *</span>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">₹</InputGroup.Text>
                  <Form.Control
                    placeholder="Price"
                    onKeyPress={handleKeyPressPrice}                    
                    value={formData.surveyorPrice}
                    onChange={(e) =>
                      handleChange("surveyorPrice", e.target.value)
                    }
                  />
                </InputGroup>
                {errors.surveyorPrice && (
                  <div className="text-danger small">
                    {errors.surveyorPrice}
                  </div>
                )}
              </div>
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <button className="btn button-secondary-color p-2 " onClick={handleReset}>
              <FontAwesomeIcon icon={faRotateLeft} /> Reset
            </button>
            <button
              onClick={handleClick}
              className="btn bg-gradient-primary p-2 "
            >
              <FontAwesomeIcon icon={faPlus} /> Add Product
            </button>
          </div>
        </Card>
      </div>
      <Grid update={update} dropDrownUpdate={dropDrownUpdate}/>
    </>
  );
};

export default Creation;
