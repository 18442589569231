import { Form } from "react-bootstrap";


export function SelectInput({ placeholder, datalistId, value, onChange, options,onKeyPress }) {
  return (
    <>
      <input
        className="form-control"
        list={datalistId}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress ={onKeyPress}

      />
      <datalist id={datalistId}>
        {options.map((option, index) => (
          <option key={index} value={option.value} />
        ))}
      </datalist>
    </>
  );
}


export function Input(props) {
  const { type, className, id, placeholder, value, onChange, label, disabled, name} =
    props;
  return (
    <>
      <Form.Control
        type={type}
        id={id}
        label={label}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={onChange} 
        disabled={disabled}
        name={name}
        autoComplete="off"
      />
    </>
  );
}
export function Textarea(props) {
  const { type, className, id, placeholder, value, onChange, label, disabled, rows } =
    props;
  return (
    <>
    <textarea
    id={id}
    rows={rows}
    label={label}
    className="form-control form-group"{...className}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    disabled={disabled}></textarea>
    </>
  );
}
export function Check(props) {
  const { type, label, id, isSelect, className, placeholder, inline, name } =
    props;

  const radioButtonStyle = {
    display: inline ? "inline-block" : "block",
  };

  return (
    <div className="checkbox radioButton" style={radioButtonStyle}>
      <Form.Check
        type={type}
        label={label}
        id={id}
        checked={isSelect}
        placeholder={placeholder}
        className={className}
        name={name}
      />
    </div>
  );
}
export function Select(props) {
  const { label, id, isSelect, className, placeholder } = props;

  return (
    <div>
      <label htmlFor={id}>{label}</label>
      <select className={`form-control ${className}`} id={id}>
        {isSelect && <option value="">{placeholder}</option>}
        {props.children}
      </select>
    </div>
  );
}