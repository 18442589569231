import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "../resources/css/custom.css";
import { Container } from "react-bootstrap";
import Sidebar from "../includes/Sidebar";
import Header from "../includes/Header";
import Footer from "../includes/Footer";
import Login from "../auth/Login";
import Forgot from "../auth/Forgot";
import Reset from "../auth/ResetPassword";
import Dashboard from "../icgms/Dashboard";
import Client from "../icgms/registration/client/Index";
import ClientCreate from "../icgms/registration/client/Creation";
import ClientShow from "../icgms/registration/client/Show";
import ClientEdit from "../icgms/registration/client/Edit";
import ICGMS from "../icgms/registration/icgms/Index";
import ICGMSCreate from "../icgms/registration/icgms/Creation";
import ICGMSShow from "../icgms/registration/icgms/Show";
import ICGMSEdit from "../icgms/registration/icgms/Edit";
import InsuranceFinancier from "../icgms/registration/insuranceFinancier/Index";
import InsuranceFinancierCreate from "../icgms/registration/insuranceFinancier/Creation";
import InsuranceFinancierShow from "../icgms/registration/insuranceFinancier/Show";
import InsuranceFinancierEdit from "../icgms/registration/insuranceFinancier/Edit";
import Repairer from "../icgms/registration/repairer/Index";
import RepairerCreate from "../icgms/registration/repairer/Creation";
import RepairerShow from "../icgms/registration/repairer/Show";
import RepairerEdit from "../icgms/registration/repairer/Edit";
import RepairerMap from "../icgms/registration/repairer/Mapped";
import RepairerReject from "../icgms/registration/repairer/Reject";
import Surveyor from "../icgms/registration/surveyor/Index";
import SurveyorCreate from "../icgms/registration/surveyor/Creation";
import SurveyorShow from "../icgms/registration/surveyor/Show";
import SurveyorEdit from "../icgms/registration/surveyor/Edit";
import SurveyorMap from "../icgms/registration/surveyor/Mapped";
import SurveyorReject from "../icgms/registration/surveyor/Reject";
import Vehicle from "../icgms/master/vehicle/Index";
import VehicleShow from "../icgms/master/vehicle/Show";
import VehicleEdit from "../icgms/master/vehicle/Edit";
import Location from "../icgms/master/location/Index";
import LocationShow from "../icgms/master/location/Show";
import LocationEdit from "../icgms/master/location/Edit";
import Product from "../icgms/master/product/Index";
import ProductShow from "../icgms/master/product/Show";
import ProductEdit from "../icgms/master/product/Edit";
import Settings from "../icgms/Settings";
import Cookies from 'js-cookie';

function Layout() {
  const [currentMenu, setCurrentMenu] = useState("Dashboard");
  const handleMenuClick = (menuName) => {
    setCurrentMenu(menuName);
  };
  const [dropdownState, setDropdownState] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

//   // Check if email and password cookies exist
//   const hasEmailCookie = Cookies.get('email') !== undefined;
//   const hasPasswordCookie = Cookies.get('password') !== undefined;

// // Redirect to /login if email or password cookies are missing
// if (!hasEmailCookie || !hasPasswordCookie) {
//   navigate('/');
//   return null; // Prevent rendering the rest of the component
// }

useEffect(() => {
  if (!Cookies.get("Auth")) {
    navigate("/");
  }
}, [navigate]);


  const isSignUpPage = location.pathname === "/" || location.pathname === "/forgot" || location.pathname.startsWith("/reset/");
  const showHeader = !isSignUpPage && location.pathname !== "/forgot" && !location.pathname.startsWith("/reset/");
  const showContainer = location.pathname !== "/" && location.pathname !== "/forgot";


  const handleToggleSidebar = () => {
    const body = document.querySelector("body");
    const mainContent = document.querySelector(".main-content");

    if (sidebarOpen) {
      body.classList.remove("g-sidenav-pinned");
      mainContent.classList.remove("overlay");
    } else {
      body.classList.add("g-sidenav-pinned");
      mainContent.classList.add("overlay");
    }
    setSidebarOpen(!sidebarOpen);
    // alert("hello");
  };
  return (
    <div className="">
      {showHeader && <Sidebar
        handleMenuClick={handleMenuClick}
        handleToggleSidebar={handleToggleSidebar}
      />}
      <main
        className={` ${isSignUpPage
            ? ""
            : "main-content position-relative max-height-vh-100 h-100 border-radius-lg"
          }`}
      >
        {showHeader && <Header currentMenu={currentMenu} handleToggleSidebar={handleToggleSidebar} />}
        <div className={`${isSignUpPage ? "" : "container-fluid py-4"}`}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/reset/:ID" element={<Reset />} />
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/client" element={<Client />} />
            <Route path="/admin/client/create" element={<ClientCreate />} />
            <Route path="/admin/client/show" element={<ClientShow />} />
            <Route path="/admin/client/edit" element={<ClientEdit />} />
            <Route path="/admin/icgms" element={<ICGMS />} />
            <Route path="/admin/icgms/create" element={<ICGMSCreate />} />
            <Route path="/admin/icgms/show" element={<ICGMSShow />} />
            <Route path="/admin/icgms/edit" element={<ICGMSEdit />} />
            <Route
              path="/admin/insurancefinancier"
              element={<InsuranceFinancier />}
            />
            <Route
              path="/admin/insurancefinancier/create"
              element={<InsuranceFinancierCreate />}
            />
            <Route
              path="/admin/insurancefinancier/show"
              element={<InsuranceFinancierShow />}
            />
            <Route
              path="/admin/insurancefinancier/edit"
              element={<InsuranceFinancierEdit />}
            />
            <Route path="/admin/repairer" element={<Repairer />} />
            <Route path="/admin/repairer/create" element={<RepairerCreate />} />
            <Route path="/admin/repairer/show" element={<RepairerShow />} />
            <Route path="/admin/repairer/edit" element={<RepairerEdit />} />
            <Route path="/admin/repairer/map" element={<RepairerMap />} />
            <Route path="/admin/repairer/reject" element={<RepairerReject />} />
            <Route path="/admin/surveyor" element={<Surveyor />} />
            <Route path="/admin/surveyor/create" element={<SurveyorCreate />} />
            <Route path="/admin/surveyor/show" element={<SurveyorShow />} />
            <Route path="/admin/surveyor/edit" element={<SurveyorEdit />} />
            <Route path="/admin/surveyor/map" element={<SurveyorMap />} />
            <Route path="/admin/surveyor/reject" element={<SurveyorReject />} />
            <Route path="/admin/vehicle" element={<Vehicle />} />
            <Route path="/admin/vehicle/show" element={<VehicleShow />} />
            <Route path="/admin/vehicle/edit" element={<VehicleEdit />} />
            <Route path="/admin/location" element={<Location />} />
            <Route path="/admin/location/show" element={<LocationShow />} />
            <Route path="/admin/location/edit" element={<LocationEdit />} />
            <Route path="/admin/product" element={<Product />} />
            <Route path="/admin/product/show" element={<ProductShow />} />
            <Route path="/admin/product/edit" element={<ProductEdit />} />
            <Route path="/admin/settings" element={<Settings />} />
          </Routes>
        </div>
        {showHeader && <Footer />}
      </main>
    </div>
  );
}

export default Layout;