import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Card,
  InputGroup,
  Form,
} from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "../includes/Inputs";
import {
  faEyeSlash,
  faEye,
  faRotateLeft,
  faCheck,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const Settings = () => {
  const [currentpasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newpasswordVisible, setNewPasswordVisible] = useState(false);
  const [reEnterpasswordVisible, setReEnterPasswordVisible] = useState(false);
  const handleClick = () => {
    Swal.fire({
      title: "Success!",
      text: "Password updated successfully",
      icon: "success",
    });
  };
  const togglecurrentPasswordVisibility = () => {
    setCurrentPasswordVisible(!currentpasswordVisible);
  };
  const togglenewPasswordVisibility = () => {
    setNewPasswordVisible(!newpasswordVisible);
  };
  const togglereEntercurrentPasswordVisibility = () => {
    setReEnterPasswordVisible(!reEnterpasswordVisible);
  };
  return (
    <>
      <div class="mb-4">
        <Card class="mt-4">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                  <FontAwesomeIcon icon={faUserTie} /> &nbsp;User Details
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Employee ID</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    value="CA-001"
                    disabled="true"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Username</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    value="John Michael"
                    disabled="true"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Official Email</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    value="johnmichael@icgms.com"
                    disabled="true"
                  />
                </div>
              </div>
            </Row>
          </Card.Body>
          <Card.Title class="card-header bg-gradient-header p-3">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                  <FontAwesomeIcon icon={faUserTie} /> &nbsp;Change Password
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body className="card-body p-3">
            <Row>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Current Password</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type={currentpasswordVisible ? "text" : "password"}
                    className={"form-control pass"}
                    placeholder="Current Password"
                    label="password"
                  />
                  <FontAwesomeIcon
                    icon={currentpasswordVisible ? faEyeSlash : faEye}
                    className="password-toggle-icon eyePosition"
                    onClick={togglecurrentPasswordVisibility}
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Enter New Password</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type={newpasswordVisible ? "text" : "password"}
                    className={"form-control pass"}
                    placeholder="Enter New Password"
                    label="password"
                  />
                  <FontAwesomeIcon
                    icon={newpasswordVisible ? faEyeSlash : faEye}
                    className="password-toggle-icon eyePosition"
                    onClick={togglenewPasswordVisibility}
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Re-Enter New Password</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type={reEnterpasswordVisible ? "text" : "password"}
                    className={"form-control pass"}
                    placeholder="Re-Enter New Password"
                    label="password"
                  />
                  <FontAwesomeIcon
                    icon={reEnterpasswordVisible ? faEyeSlash : faEye}
                    className="password-toggle-icon eyePosition"
                    onClick={togglereEntercurrentPasswordVisibility}
                  />
                </div>
              </div>
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <Link to="" className="btn button-secondary-color p-2 ">
              <FontAwesomeIcon icon={faRotateLeft} /> Reset
            </Link>
            <Link
              onClick={handleClick}
              className="btn bg-gradient-primary p-2 "
            >
              <FontAwesomeIcon icon={faCheck} /> Update Password
            </Link>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Settings;
