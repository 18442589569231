import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  success: false,
};

export const forgetSlice = createSlice({
  name: 'forget',
  initialState,
  reducers: {
    forgotPasswordRequest: (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    forgotPasswordSuccess: (state) => {
      state.loading = false;
      state.success = true;
    },
    forgotPasswordFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { forgotPasswordRequest, forgotPasswordSuccess, forgotPasswordFailure } = forgetSlice.actions;

export default forgetSlice.reducer;
