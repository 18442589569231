import React, { useState, useEffect } from "react";
import { Button, Container, Row, Card } from "react-bootstrap";
import Select from "react-select";
import { Table, Badge, Image } from "react-bootstrap";
import { Input } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2';
import {
  faPenToSquare,
  faTrashCan,
  faFileExcel,
  faFilePdf,
  faPrint,
  faRotateLeft,
  faEye,
  faArrowUp,
  faListUl,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import { productdropdgridApi, productdeleteApi } from "../../../api/apiPath";

function Grid(props) {
  const [gridData, setGridData] = useState([]);

  const [filter, setFilter] = useState({
    category: "",
    name: "",
    perCasePriceForClient: "",
    perCasePriceForSurveyor: ""
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Set the number of items per page
  const user_id = Cookies.get('user_id');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await productdropdgridApi({ user_id: user_id });
        setGridData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [props.update]);

  const handleFilterChange = (e) => {

    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const filteredData = gridData.filter((item) => {

    return (
      item.category.toLowerCase().includes(filter.category.toLowerCase()) &&
      item.name.toLowerCase().includes(filter.name.toLowerCase()) &&
      item.price_for_client
        .toLowerCase()
        .includes(filter.perCasePriceForClient.toLowerCase()) &&
      item.price_for_surveyor
        .toLowerCase()
        .includes(filter.perCasePriceForSurveyor.toLowerCase())
    );
  });

  const handleDelete = async (rowData) => {  //delete function
    const { id } = rowData;

    const { value: password, isConfirmed } = await Swal.fire({
      title: 'Enter your password',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
        required: true,
        autoComplete: "off",
      },
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter password';
        }
        return undefined;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      didClose: async () => {
        if (isConfirmed) {
          try {
            const response = await productdeleteApi({ id, user_id, password });
            if (response.data.success) {
              Swal.fire({
                title: 'Success!',
                text: 'product has been deleted successfully',
                icon: 'success',
              });
              const updatedGridData = gridData.filter((item) => item.id !== id);
              setGridData(updatedGridData);
              props.dropDrownUpdate.current();
            } else {
              Swal.fire({
                title: 'Error',
                text: response.data.message,
                icon: 'error',
              });
            }
          } catch (error) {
            Swal.fire({
              title: 'Error',
              text: 'An error occurred while deleting the location',
              icon: 'error',
            });
          }
        } else {
          console.log('Operation canceled');
        }
      },
    });
  };

  const handleEditClick = (rowData) => { // edite function
    console.log(rowData);
    navigate('/admin/product/edit', { state: { data: rowData } });
  };

  const indexOfLastItem = currentPage * itemsPerPage;   //pagination
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <div>
      <div className="card mt-3 mb-6">
        <div class="card-header bg-gradient-header p-3 card-dash">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 d-flex align-items-center">
              <h6 class="mb-0 text-white">
                <FontAwesomeIcon icon={faListUl} />
                &nbsp; List of Products
              </h6>
            </div>
            <div class="col-12 col-sm-12 col-md-6 text-end">
              <a
                href=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faFileExcel} /> Export XLS
              </a>
              <a
                href=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faFilePdf} /> PDF
              </a>
              <a
                href=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faPrint} /> Print
              </a>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <Table className="table align-items-center mb-0">
            <thead>
              <tr>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Category
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Product Category"
                    name="category"
                    value={filter.category}
                    onChange={handleFilterChange}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center ">
                  Name
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Product Name"
                    name="name"
                    value={filter.name}
                    onChange={handleFilterChange}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center ">
                  Description
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center ">
                  Per case price for client
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Per case price for surveyor"
                    name="perCasePriceForClient"
                    value={filter.perCasePriceForClient}
                    onChange={handleFilterChange}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center ">
                  Per case price for surveyor
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Per case price for surveyor"
                    name="perCasePriceForSurveyor"
                    value={filter.perCasePriceForSurveyor}
                    onChange={handleFilterChange}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <h6 className="text-sm font-weight-bold mb-0">
                      No records found
                    </h6>
                  </td>
                </tr>
              ) : (
                currentItems.map((rowData, index) => (
                  <tr key={index}>
                    <td>
                      <h6 className="text-sm font-weight-bold mb-0 text-center">
                        {rowData.category}
                      </h6>
                    </td>
                    <td>
                      <h6 className="text-sm font-weight-bold mb-0 text-center">
                        {rowData.name}
                      </h6>
                    </td>
                    <td>
                      <h6 className="text-sm font-weight-bold mb-0 text-center">
                        {rowData.description}
                      </h6>
                    </td>
                    <td>
                      <h6 className="text-sm font-weight-bold mb-0 text-center">
                        {rowData.price_for_client}
                      </h6>
                    </td>
                    <td>
                      <h6 className="text-sm font-weight-bold mb-0 text-center">
                        {rowData.price_for_surveyor}
                      </h6>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex gap-3 justify-content-center">
                        <div>
                          <a
                            href="/admin/location/edit"
                            className="font-weight-bold text-xs"
                            data-toggle="tooltip"
                            data-original-title="Edit user"
                            onClick={(e) => {
                              e.preventDefault();
                              handleEditClick(rowData);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              className="text-info"
                              size="sm"
                            />
                            &nbsp;
                            <span className="d-none d-md-inline-block">Edit</span>
                          </a>
                        </div>
                        <td style={{ display: 'none' }}>
                          {rowData.id}
                        </td>
                        <div>
                          <a
                            href="#!"
                            className="font-weight-bold text-xs"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(rowData);
                            }}
                            data-toggle="tooltip"
                            data-original-title="Delete"
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="text-danger"
                              size="sm"
                            />
                            &nbsp;
                            <span className="d-none d-md-inline-block">Delete</span>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
        {currentItems.length > 0 && (
          <div className="mt-3">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <a
                    className="page-link"
                    href="javascript:;"
                    tabIndex="-1"
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <i className="fa fa-angle-left"></i>
                    <span className="sr-only">Previous</span>
                  </a>
                </li>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(number => (
                  <li
                    key={number}
                    className={`page-item ${currentPage === number ? 'active' : ''}`}
                  >
                    <a
                      className="page-link"
                      href="javascript:;"
                      onClick={() => setCurrentPage(number)}
                    >
                      {number}
                    </a>
                  </li>
                ))}
                <li
                  className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                >
                  <a
                    className="page-link"
                    href="javascript:;"
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <i className="fa fa-angle-right"></i>
                    <span className="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </div>
  )
}

export default Grid
