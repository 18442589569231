import React from "react";
import { Button, Container, Row, Card,InputGroup,Form } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "../../../includes/Inputs";
import {
  faPenToSquare,
  faArrowLeft,
  faCube,
} from "@fortawesome/free-solid-svg-icons";

const Creation = () => {
  const country = [
    { value: "india", label: "India" },
    { value: "america", label: "America" },
    { value: "england", label: "England" },
  ];
  const zone = [
    { value: "east", label: "East" },
    { value: "north", label: "North" },
    { value: "south", label: "South" },
  ];
  const state = [
    { value: "tamilnadu", label: "Tamilnadu" },
    { value: "kerala", label: "Kerala" },
    { value: "karnataka", label: "Karnataka" },
  ];
  const city = [
    { value: "chennai", label: "Chennai" },
    { value: "coimbatore", label: "Coimbatore" },
    { value: "madurai", label: "Madurai" },
  ];
  return (
    <>
      <div class="mb-4">
        <Card class="mt-4">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                <FontAwesomeIcon icon={faCube} /> &nbsp;Product
                </h6>
              </div>
              <div className="col-6 d-flex gap-3 justify-content-end pe-2 ">
                <a
                  href="/admin/product/edit"
                  className="btn bg-gradient-primary p-2 mb-0"
                >
                  <FontAwesomeIcon icon={faPenToSquare} /> Edit
                </a>
                <a
                  href="/admin/product"
                  className="btn bg-gradient-primary p-2 mb-0"
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Back
                </a>
              </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Product Category</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Product Category"
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Product Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Product Name"
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Product Description</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value="Product Description"
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
              <label class="form-control-label">Per case price</label>
              <InputGroup>
        <InputGroup.Text id="basic-addon1">₹</InputGroup.Text>
        <Form.Control
          placeholder="Price"
          aria-label="Username"
          value="5000"
          aria-describedby="basic-addon1"
          disabled
        />
      </InputGroup>
      </div>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default Creation;