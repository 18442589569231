import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Check, Input } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { locationgridApi, locationdeleteApi } from "../../../api/apiPath";
import Swal from 'sweetalert2';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { faPenToSquare, faTrashCan, faFileExcel, faFilePdf, faPrint, faListUl } from "@fortawesome/free-solid-svg-icons";

function Grid(props) {
  const [gridData, setGridData] = useState([]);
  const [filter, setFilter] = useState({
    city: "",
    state: "",
    zone: "",
    country: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Set the number of items per page
  const user_id = Cookies.get('user_id');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await locationgridApi({ user_id: user_id });
        setGridData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [props.update]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));
  };

  const filteredData = gridData.filter((item) => { //filter data
    return (
      item.city.toLowerCase().includes(filter.city.toLowerCase()) &&
      item.state.toLowerCase().includes(filter.state.toLowerCase()) &&
      item.zone.toLowerCase().includes(filter.zone.toLowerCase()) &&
      item.country.toLowerCase().includes(filter.country.toLowerCase())
    );
  });
  
  const handleDelete = async (rowData) => {  //delete function
    const { id } = rowData;

    const { value: password, isConfirmed } = await Swal.fire({
      title: 'Enter your password',
      input: 'password',
      inputAttributes: {
        autocapitalize: 'off',
        required: true,
        autoComplete: "off",
      },
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return 'Please enter password';
        }
        return undefined;
      },
      allowOutsideClick: () => !Swal.isLoading(),
      didClose: async () => {
        if (isConfirmed) {
          try {
            const response = await locationdeleteApi({ id, user_id, password });
            if (response.data.success) {
              Swal.fire({
                title: 'Success!',
                text: 'Location has been deleted successfully',
                icon: 'success',
              });
              const updatedGridData = gridData.filter((item) => item.id !== id);
              setGridData(updatedGridData);
              props.dropDrownUpdate.current();
            } else {
              Swal.fire({
                title: 'Error',
                text: response.data.message || 'An error occurred while deleting the location',
                icon: 'error',
              });
            }
          } catch (error) {
            Swal.fire({
              title: 'Error',
              text: 'An error occurred while deleting the location',
              icon: 'error',
            });
          }
        } else {
          // Handle case when the user closes the dialog without confirming
          console.log('Operation canceled');
        }
      },
    });
  };

  const handleEditClick = (rowData) => { // edite function
    console.log(rowData); 
    navigate('/admin/location/edit', { state: { data: rowData } });
  };

  //pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <div>
      <div className="card mt-3 mb-6">
        <div className="card-header bg-gradient-header p-3 card-dash">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 d-flex align-items-center">
              <h6 className="mb-0 text-white">
                <FontAwesomeIcon icon={faListUl} />
                &nbsp; List of Locations
              </h6>
            </div>
            <div className="col-12 col-sm-12 col-md-6 text-end">
              <a
                href=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faFileExcel} /> Export XLS
              </a>
              <a
                href=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faFilePdf} /> PDF
              </a>
              <a
                href=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faPrint} /> Print
              </a>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <Table className="table align-items-center mb-0">
            <thead>
              <tr>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                  City
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="City"
                    name="city"
                    value={filter.city}
                    onChange={handleFilterChange}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                  State
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="State"
                    name="state"
                    value={filter.state}
                    onChange={handleFilterChange}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center ">
                  Zone
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Zone"
                    name="zone"
                    value={filter.zone}
                    onChange={handleFilterChange}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                  Country
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Country"
                    name="country"
                    value={filter.country}
                    onChange={handleFilterChange}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <h6 className="text-sm font-weight-bold mb-0">
                      No records found
                    </h6>
                  </td>
                </tr>
              ) : (
                currentItems.map((rowData, index) => (
                  <tr key={index}>
                    <td>
                      <h6 className="text-sm font-weight-bold mb-0 text-center">
                        {rowData.city}
                      </h6>
                    </td>
                    <td>
                      <h6 className="text-sm font-weight-bold mb-0 text-center">
                        {rowData.state}
                      </h6>
                    </td>
                    <td>
                      <h6 className="text-sm font-weight-bold mb-0 text-center">
                        {rowData.zone}
                      </h6>
                    </td>
                    <td>
                      <h6 className="text-sm font-weight-bold mb-0 text-center">
                        {rowData.country}
                      </h6>
                    </td>
                    <td className="align-middle">
                      <div className="d-flex gap-3 justify-content-center">
                        <div>
                          <a
                            href="/admin/location/edit"
                            className="font-weight-bold text-xs"
                            data-toggle="tooltip"
                            data-original-title="Edit user"
                            onClick={(e) => {
                              e.preventDefault();
                              handleEditClick(rowData);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPenToSquare}
                              className="text-info"
                              size="sm"
                            />
                            &nbsp;
                            <span className="d-none d-md-inline-block">Edit</span>
                          </a>
                        </div>
                        <td style={{ display: 'none' }}>
                          {rowData.id}
                        </td>
                        <div>
                          <a
                            href="#!"
                            className="font-weight-bold text-xs"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(rowData);
                            }}
                            data-toggle="tooltip"
                            data-original-title="Delete"
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="text-danger"
                              size="sm"
                            />
                            &nbsp;
                            <span className="d-none d-md-inline-block">Delete</span>
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
        {currentItems.length > 0 && ( <div className="mt-3">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <a
                  className="page-link"
                  href="javascript:;"
                  tabIndex="-1"
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <i className="fa fa-angle-left"></i>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              {Array.from({ length: Math.ceil(gridData.length / itemsPerPage) }, (_, i) => i + 1).map(number => (
                <li
                  key={number}
                  className={`page-item ${currentPage === number ? 'active' : ''}`}
                >
                  <a
                    className="page-link"
                    href="javascript:;"
                    onClick={() => setCurrentPage(number)}
                  >
                    {number}
                  </a>
                </li>
              ))}
              <li
                className={`page-item ${currentPage === Math.ceil(gridData.length / itemsPerPage)
                  ? 'disabled'
                  : ''
                  }`}
              >
                <a
                  className="page-link"
                  href="javascript:;"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <i className="fa fa-angle-right"></i>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>)}
      </div>
    </div>
  );
}

export default Grid;
