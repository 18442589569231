import React, { useState, useEffect } from "react";
import { Button, Container, Row, Card } from "react-bootstrap";
import { Link,useNavigate } from "react-router-dom";
import Select from "react-select";
import { Table, Badge, Image } from "react-bootstrap";
import { Check, Input } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import frontImage from "../../../resources/img/frontCar.jpg";
import backImage from "../../../resources/img/backCar.jpg";
import leftImage from "../../../resources/img/rightCar.jpg";
import rightImage from "../../../resources/img/leftCar.jpg";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { vehicalDetailsListApi, vehicalImageApi,deleteVehicleApi } from "../../../api/apiPath";

import {
  faPenToSquare,
  faTrashCan,
  faEye,
  faListUl,
  faFileExcel,
  faFilePdf,
  faPrint,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";



function MyVerticallyCenteredModal(props) {
  const { selectedRowData,imageResponseData } = props;
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [rightImage, setRightImage] = useState(null);
  const [leftImage, setLeftImage] = useState(null);
  

  useEffect(() => {

    if(selectedRowData !== null){
      fetchSelectedImage();
    }

  },[selectedRowData]);

  
  const fetchSelectedImage = async () => {
    try {
      const response = await vehicalImageApi({ loggedInuser_id: Cookies.get("user_id"), selectedRowId:selectedRowData.id});
      const responseData = response.data;

      setFrontImage(responseData.frontImage);
      setBackImage(responseData.backImage);
      setRightImage(responseData.rightImage);
      setLeftImage(responseData.leftImage);
      return responseData;
    } catch (error) {
      console.error("Error fetching vehicle list:", error);
      throw error;
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-gradient-header">
        <Modal.Title id="contained-modal-title-vcenter" className="text-white">
          Vehicle Images
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <div className="form-group">
              <div className="mt-4 text-center">
                <div className="card text-center">
                  <img
                    src={`data:image/png;base64,${frontImage}`}
                    alt="Front"
                  />
                  <p className="form-control-label">Front</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <div className="form-group">
              <div className="mt-4 text-center">
                <div className="card text-center">
                  <img
                    src={`data:image/png;base64,${backImage}`}
                    alt="Back"
                  />
                  <p className="form-control-label">Back</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <div className="form-group">
              <div className="mt-4 text-center">
                <div className="card text-center">
                  <img
                    src={`data:image/png;base64,${rightImage}`}
                    alt="Right"
                  />
                  <p className="form-control-label">Right</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3">
            <div className="form-group">
              <div className="mt-4 text-center">
                <div className="card text-center">
                  <img
                    src={`data:image/png;base64,${leftImage}`}
                    alt="Left"
                  />
                  <p className="form-control-label">Left</p>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <Button
          onClick={props.onHide}
          className="float-right bg-gradient-primary"
        >
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
}





function Grid({showGridList}) {
  const [modalShow, setModalShow] = React.useState(false);
  const [userId, setUserId] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [imageResponseData, setImageResponseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); 


  const navigate = useNavigate();
   
  useEffect(() => {
    setUserId(Cookies.get("user_id"));
    fetchVehicalList();
  }, [showGridList]);

  const fetchVehicalList = async () => {
    try {
      const response = await vehicalDetailsListApi({ loggedInuser_id: Cookies.get("user_id") });
      const responseData = response.data;
      setResponseData(response.data.rows);
      setFilteredData(response.data.rows);
      return responseData;
    } catch (error) {
      console.error("Error fetching vehicle list:", error);
      throw error;
    }
  };


  // useEffect(() => {

  //   if(selectedRowData !== null){
  //     fetchSelectedImage();
  //   }

  // },[]);


  const handleInputChange = (e, field) => {
    const inputValue = e.target.value.toLowerCase();
    const updatedFilteredData = responseData.filter(data => data[field].toLowerCase().includes(inputValue));
    setFilteredData(updatedFilteredData);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleEdit = async (data) => {

        try {
          const response = await vehicalImageApi({ loggedInuser_id: Cookies.get("user_id"), selectedRowId:data.id});
          sessionStorage.setItem("id", data.id);
          sessionStorage.setItem("class", data.class);
          sessionStorage.setItem("tb", data.type_of_body);
          sessionStorage.setItem("nm", data.name_of_manufacturer);
          sessionStorage.setItem("make", data.make);
          sessionStorage.setItem("model", data.model);
          sessionStorage.setItem("varient", data.variant);
          sessionStorage.setItem("sc", data.seating_capacity);
          sessionStorage.setItem("hp", data.horse_power);
          sessionStorage.setItem("cc", data.cubic_capacity);
          sessionStorage.setItem("fu", data.fuel_used);
          sessionStorage.setItem("rlw", data.reg_laden_weight);
          sessionStorage.setItem("unlw", data.unladen_weight);
          sessionStorage.setItem("frontImage", `data:image/png;base64,${response.data.frontImage[0]}`);
          sessionStorage.setItem("backImage", `data:image/png;base64,${response.data.backImage[0]}`);
          sessionStorage.setItem("leftImage", `data:image/png;base64,${response.data.leftImage[0]}`);
          sessionStorage.setItem("rightImage", `data:image/png;base64,${response.data.rightImage[0]}`);
          sessionStorage.setItem("Action", "edit");

          // setTimeout(() => {
          //   for (const key in sessionStorage) {
          //     if (key !== "Action" && key !== "id") {
          //       sessionStorage.removeItem(key);
          //     }
          //   }
          // }, 5000);


          return responseData;
        } catch (error) {
          console.error("Error fetching vehicle list:", error);
          throw error;
        }
  
}

const handleDelete = async (data) => {  

  const { value: password, isConfirmed } = await Swal.fire({
    title: 'Enter your password',
    input: 'password',
    inputAttributes: {
      autocapitalize: 'off',
      required: true,
      autoComplete: "off",
    },
    showCancelButton: true,
    confirmButtonText: 'Confirm',
    showLoaderOnConfirm: true,
    inputValidator: (value) => {
      if (!value) {
        return 'Please enter password';
      }
      return undefined;
    },
    allowOutsideClick: () => !Swal.isLoading(),
    didClose: async () => {
      if (isConfirmed) {
        try {

          const response = await deleteVehicleApi({ id: data.id, user_id:userId , password:password });
          if (response.data.success) {
            Swal.fire({
              title: 'Success!',
              text: 'Vehicle has been deleted successfully',
              icon: 'success',
            });
            await fetchVehicalList();
          } else {
            Swal.fire({
              title: 'Error',
              text: response.data.message || 'An error occurred while deleting the vehicle',
              icon: 'error',
            });
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'An error occurred while deleting the vehicle',
            icon: 'error',
          });
        }
      } else {
        // Handle case when the user closes the dialog without confirming
        console.log('Operation canceled');
      }
    },
  });
};

const handleView = async (data) => {

  try {
    const response = await vehicalImageApi({ loggedInuser_id: Cookies.get("user_id"), selectedRowId:data.id});
    sessionStorage.setItem("id", data.id);
    sessionStorage.setItem("class", data.class);
    sessionStorage.setItem("tb", data.type_of_body);
    sessionStorage.setItem("nm", data.name_of_manufacturer);
    sessionStorage.setItem("make", data.make);
    sessionStorage.setItem("model", data.model);
    sessionStorage.setItem("varient", data.variant);
    sessionStorage.setItem("sc", data.seating_capacity);
    sessionStorage.setItem("hp", data.horse_power);
    sessionStorage.setItem("cc", data.cubic_capacity);
    sessionStorage.setItem("fu", data.fuel_used);
    sessionStorage.setItem("rlw", data.reg_laden_weight);
    sessionStorage.setItem("unlw", data.unladen_weight);
    sessionStorage.setItem("frontImage", `data:image/png;base64,${response.data.frontImage[0]}`);
    sessionStorage.setItem("backImage", `data:image/png;base64,${response.data.backImage[0]}`);
    sessionStorage.setItem("leftImage", `data:image/png;base64,${response.data.leftImage[0]}`);
    sessionStorage.setItem("rightImage", `data:image/png;base64,${response.data.rightImage[0]}`);
    sessionStorage.setItem("Action", "View");
    navigate('/admin/vehicle/show');


    // setTimeout(() => {
    //   for (const key in sessionStorage) {
    //     if (key !== "Action" && key !== "id") {
    //       sessionStorage.removeItem(key);
    //     }
    //   }
    // }, 5000);

    return responseData;
  } catch (error) {
    console.error("Error fetching vehicle list:", error);
    throw error;
  }
}


  return (
    <div className="pb-3">
      <div className="card mb-6">
        <div class="card-header bg-gradient-header p-3 card-dash">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6 d-flex align-items-center">
              <h6 class="mb-0 text-white">
                <FontAwesomeIcon icon={faListUl} />
                &nbsp; List of Vehicles
              </h6>
            </div>
            <div class="col-12 col-sm-12 col-md-6 text-end">
              <Link

                to=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faFileExcel} /> Export XLS
              </Link>
              <Link
                to=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faFilePdf} /> PDF
              </Link>
              <Link
                to=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faPrint} /> Print
              </Link>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <Table className="table align-items-center mb-0">
            <thead>
              <tr>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder">
                  Class
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Class"
                    onChange={(e) => handleInputChange(e, 'class')}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder">
                  Type of body
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="End Use"
                    onChange={(e) => handleInputChange(e, 'type_of_body')}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder">
                  Name of manufacturer
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Name of manufacturer"
                    onChange={(e) => handleInputChange(e, 'name_of_manufacturer')}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder">
                  Make
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Make"
                    onChange={(e) => handleInputChange(e, 'make')}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder">
                  Model
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Model"
                    onChange={(e) => handleInputChange(e, 'model')}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder">
                  Varient
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Varient"
                    onChange={(e) => handleInputChange(e, 'variant')}
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                  Images
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
            {filteredData.map((data, index) => (
        <tr key={index}>
          <td>
            <h6 className="ps-3 text-sm">{data.class}</h6>
          </td>
          <td>
            <h6 className="ps-3 text-sm">{data.type_of_body}</h6>
          </td>
          <td>
            <h6 className="ps-3 text-sm">{data.name_of_manufacturer}</h6>
          </td>
          <td>
            <h6 className="ps-3 text-sm">{data.make}</h6>
          </td>
          <td>
            <h6 className="ps-3 text-sm">{data.model}</h6>
          </td>
          <td>
            <h6 className="ps-3 text-sm">{data.variant}</h6>
          </td>
          <td>
            <h6 className="ps-3 text-sm text-center">
            <p
              onClick={() => {
                setSelectedRowData(data); // Set the selected row data
                setModalShow(true);

              }}
              className="btn bg-gradient-primary p-1 exportbtn me-2 mb-0"
              size="lg"
            >Vehicle</p>
            </h6>
            <MyVerticallyCenteredModal
              show={modalShow}
              onHide={() => setModalShow(false)}
              selectedRowData={selectedRowData}
              imageResponseData={imageResponseData}
            />
          </td>
          <td className="align-middle pt-0">
            <div className="d-flex gap-3 justify-content-center">
              <div>
                <Link
                  to=""
                  className="font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="View"
                  onClick={() => {
                    setSelectedRowData(data);
                    handleView(data);
                  }}
                >
                  <FontAwesomeIcon icon={faEye} size="sm" />
                  &nbsp;
                  <span className="d-none d-md-inline-block">View</span>
                </Link>
              </div>
              <div>
                <Link
                  to=""
                  className="font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Edit user"
                  onClick={() => {
                    setSelectedRowData(data);
                    handleEdit(data);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    className="text-info"
                    size="sm"
                  />
                  &nbsp;
                  <span className="d-none d-md-inline-block">Edit</span>
                </Link>
              </div>
              <div>
              <a
                            href="#!"
                            className="font-weight-bold text-xs"
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(data);
                            }}
                            data-toggle="tooltip"
                            data-original-title="Delete"
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="text-danger"
                              size="sm"
                            />
                            &nbsp;
                            <span className="d-none d-md-inline-block">Delete</span>
                          </a>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
          </Table>
        </div>
        {currentItems.length > 0 && ( <div className="mt-3">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <a
                  className="page-link"
                  href="javascript:;"
                  tabIndex="-1"
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <i className="fa fa-angle-left"></i>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              {Array.from({ length: Math.ceil(responseData.length / itemsPerPage) }, (_, i) => i + 1).map(number => (
                <li
                  key={number}
                  className={`page-item ${currentPage === number ? 'active' : ''}`}
                >
                  <a
                    className="page-link"
                    href="javascript:;"
                    onClick={() => setCurrentPage(number)}
                  >
                    {number}
                  </a>
                </li>
              ))}
              <li
                className={`page-item ${currentPage === Math.ceil(responseData.length / itemsPerPage)
                  ? 'disabled'
                  : ''
                  }`}
              >
                <a
                  className="page-link"
                  href="javascript:;"
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <i className="fa fa-angle-right"></i>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>)}
      </div>
    </div>
  );
}

export default Grid;
