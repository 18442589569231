import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userType: '',
  employeeID: '',
  firstName: '',
  lastName: '',
  designation: '',
  department: '',
  branchName: '',
  country: '',
  zone: '',
  state: '',
  city: '',
  reportManager: '',
  offMail: '',
  perMail: '',
  offPhone: '',
  whatsPhone: '',
  perPhoneNum1: '',
  perPhoneNum2: '',
  gender: '',
  perAddress: '',
  address1: '',
  address2: '',
  address3: '',
  cityP: '',
  stateP: '',
  pincode: '',
  countryP:'',
  productModule:'',
  role:'',
  // Define similar initial states for other fields
  userTypeError: '',
  employeeIDError: '',
  firstNameError: '',
  lastNameError: '',
  designationError: '',
  departmentError: '',
  branchNameError: '',
  countryError: '',
  zoneError: '',
  stateError: '',
  cityError: '',
  reportManagerError: '',
  offMailError: '',
  perMailError: '',
  offPhoneError: '',
  whatsPhoneError: '',
  perPhoneNum1Error: '',
  perPhoneNum2Error: '',
  genderError: '',
  perAddressError: '',
  address1Error: '',
  address2Error: '',
  address3Error: '',
  cityPError: '',
  statePError: '',
  pincodeError: '',
  countryPError:'',
  productModuleError:'',
  roleError:'',
  // Define similar error states for other fields
};

const icgmsRegSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setEmployeeID: (state, action) => {
      state.employeeID = action.payload;
    },
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setDesignation: (state, action) => {
      state.designation = action.payload;
    },
    setDepartment: (state, action) => {
      state.designation = action.payload;
    },
    setBranchName: (state, action) => {
      state.branchName = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setZone: (state, action) => {
      state.zone = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setReportManager: (state, action) => {
      state.reportManager = action.payload;
    },
    setOffMail: (state, action) => {
      state.offMail = action.payload;
    },
    setPerMail: (state, action) => {
      state.perMail = action.payload;
    },
    setOffPhone: (state, action) => {
      state.offPhone = action.payload;
    },
    setWhatsPhone: (state, action) => {
      state.whatsPhone = action.payload;
    },
    setPerPhoneNum1: (state, action) => {
      state.perPhoneNum1 = action.payload;
    },
    setPerPhoneNum2: (state, action) => {
      state.perPhoneNum2 = action.payload;
    },
    setGender: (state, action) => {
      state.gender = action.payload;
    },
    setPerAddress: (state, action) => {
      state.perAddress = action.payload;
    },
    setAddress1: (state, action) => {
      state.address1 = action.payload;
    },
    setAddress2: (state, action) => {
      state.address2 = action.payload;
    },
    setAddress3: (state, action) => {
      state.address3 = action.payload;
    },

    // Error reducers
    setUserTypeError: (state, action) => {
      state.userTypeError = action.payload;
    },
    setEmployeeIDError: (state, action) => {
      state.employeeIDError = action.payload;
    },
    setFirstNameError: (state, action) => {
      state.firstNameError = action.payload;
    },
    setLastNameError: (state, action) => {
      state.lastNameError = action.payload;
    },
    setDesignationError: (state, action) => {
      state.designationError = action.payload;
    },
    setDepartmentError: (state, action) => {
      state.designationError = action.payload;
    },
    setBranchNameError: (state, action) => {
      state.branchNameError = action.payload;
    },
    setCountryError: (state, action) => {
      state.countryError = action.payload;
    },
    setZoneError: (state, action) => {
      state.zoneError = action.payload;
    },
    setStateError: (state, action) => {
      state.stateError = action.payload;
    },
    setCityError: (state, action) => {
      state.stateError = action.payload;
    },
    setReportManagerError: (state, action) => {
      state.reportManagerError = action.payload;
    },
    setOffMailError: (state, action) => {
      state.offMailError = action.payload;
    },
    setPerMailError: (state, action) => {
      state.perMailError = action.payload;
    },
    setOffPhoneError: (state, action) => {
      state.offPhoneError = action.payload;
    },
    setWhatsPhoneError: (state, action) => {
      state.whatsPhoneError = action.payload;
    },
    setPerPhoneNum1Error: (state, action) => {
      state.perPhoneNum1Error = action.payload;
    },
    setPerPhoneNum2Error: (state, action) => {
      state.perPhoneNum2Error = action.payload;
    },
    setGenderError: (state, action) => {
      state.genderError = action.payload;
    },
    setPerAddressError: (state, action) => {
      state.perAddressError = action.payload;
    },
    setAddress1Error: (state, action) => {
      state.address1Error = action.payload;
    },
    setAddress2Error: (state, action) => {
      state.address2Error = action.payload;
    },
    setAddress3Error: (state, action) => {
      state.address3Error = action.payload;
    },
  },
});

export const {
  setUserType,
  setEmployeeID,
  setFirstName,
  setLastName,
  setDesignation,
  setDepartment,
  setBranchName,
  setCountry,
  setZone,
  setState,
  setCity,
  setReportManager,
  setOffMail,
  setPerMail,
  setOffPhone,
  setWhatsPhone,
  setPerPhoneNum1,
  setPerPhoneNum2,
  setGender,
  setPerAddress,
  setAddress1,
  setAddress2,
  setAddress3,
  setCityP,
  setStateP,
  setPincode,
  setCountryP,
  setProductModule,
  setRole,



  setUserTypeError,
  setEmployeeIDError,
  setFirstNameError,
  setLastNameError,
  setDesignationError,
  setDepartmentError,
  setBranchNameError,
  setCountryError,
  setZoneError,
  setStateError,
  setCityError,
  setReportManagerError,
  setOffMailError,
  setPerMailError,
  setOffPhoneError,
  setWhatsPhoneError,
  setPerPhoneNum1Error,
  setPerPhoneNum2Error,
  setGenderError,
  setPerAddressError,
  setAddress1Error,
  setAddress2Error,
  setAddress3Error,
  setCityPError,
  setStatePError,
  setPincodeError,
  setCountryPError,
  setProductModuleError,
  setRoleError,
  // Include similar actions for other fields
} = icgmsRegSlice.actions;

export default icgmsRegSlice.reducer;