import React from 'react'
import Creation from './Creation'

import Show from './Show'
function Index() {
  return (
    <div>
      <Creation/>
      {/* <Show/> */}
      
    </div>
  )
}

export default Index
