import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logoct from "../resources/img/azure.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

function Header({ handleToggleSidebar, currentMenu }) {
  const handleLogout = () => {
    clearAllCookies();
  }
  const clearAllCookies = () => {
    const cookies = Cookies.get(); // Get all cookies
    for (const cookie in cookies) {
      Cookies.remove(cookie); // Remove each cookie
    }
  };
  
  return (
    <>
      <nav
        className="navbar shadow navbar-main navbar-expand-lg px-0 mb-3 mt-3 mx-4 shadow-blur border-radius-xl position-sticky blur header-bg shadow-blur my-2 left-auto top-1 z-index-sticky"
        id="navbarBlur"
        navbar-scroll="true"
      >
        <div className="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <h6 className="font-weight-bolder mb-0">{currentMenu}</h6>
          </nav>
          <div
            className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
            id="navbar"
          >
            <div className="ms-md-auto pe-md-3 d-flex align-items-center"></div>
            <ul className="navbar-nav  justify-content-end">
              <li className="nav-item px-3 d-flex align-items-center">
                <Link to="/admin/settings" className="nav-link text-body p-0">
                  <i className="fa fa-cog fixed-plugin-button-nav"></i>
                </Link>
              </li>
              <li className="nav-item d-xl-none px-3 d-flex align-items-center">
                <Link
                  to="javascript:;"
                  className="nav-link text-body p-0"
                  id="iconNavbarSidenav"
                  onClick={handleToggleSidebar}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                    <i className="sidenav-toggler-line"></i>
                  </div>
                </Link>
              </li>
              <li className="nav-item d-flex align-items-center profile">
                <Link
                  to="javascript:;"
                  className="nav-link text-body font-weight-bold px-0 dropdown"
                >
                  <i className="fa fa-user me-sm-1 text-primary"></i>
                  <div class="dropdown-content">
                    <div>
                      <Link to="/" className="mb-0" onClick={handleLogout}>
                        <FontAwesomeIcon icon={faRightFromBracket} />{" "}
                        &nbsp;Logout
                      </Link>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="nav-item dropdown pe-2 d-flex align-items-center">
                <Link
                  to="javascript:;"
                  className="nav-link text-body p-0"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* <i className="fa fa-bell cursor-pointer"></i> */}
                </Link>
                <ul
                  className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li className="mb-2">
                    <Link
                      className="dropdown-item border-radius-md"
                      to="javascript:;"
                    >
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <img
                            src={logoct}
                            className="avatar avatar-sm  me-3 "
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="font-weight-bold">
                              New message
                            </span>{" "}
                            from Laur
                          </h6>
                          <p className="text-xs text-secondary mb-0 ">
                            <i className="fa fa-clock me-1"></i>
                            13 minutes ago
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link
                      className="dropdown-item border-radius-md"
                      to="javascript:;"
                    >
                      <div className="d-flex py-1">
                        <div className="my-auto">
                          <img
                            src={logoct}
                            className="avatar avatar-sm bg-gradient-dark  me-3 "
                          />
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            <span className="font-weight-bold">New album</span>{" "}
                            by Travis Scott
                          </h6>
                          <p className="text-xs text-secondary mb-0 ">
                            <i className="fa fa-clock me-1"></i>1 day
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item border-radius-md"
                      to="javascript:;"
                    >
                      <div className="d-flex py-1">
                        <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto"></div>
                        <div className="d-flex flex-column justify-content-center">
                          <h6 className="text-sm font-weight-normal mb-1">
                            Payment successfully completed
                          </h6>
                          <p className="text-xs text-secondary mb-0 ">
                            <i className="fa fa-clock me-1"></i>2 days
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;