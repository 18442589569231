import React from 'react'
import Creation from './Creation'
import Grid from './Grid'
import Show from './Show'
function Index() {
  return (
    <div>
      <Grid/>      
    </div>
  )
}

export default Index
