import React, { useState, useEffect } from "react";
import { Card, Row } from "react-bootstrap";
import { Input } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate, useLocation } from "react-router-dom";
import { faLocationDot, faArrowLeft, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { locationupdateApi,locationdeleteApi } from "../../../api/apiPath";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Creation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user_id = Cookies.get('user_id');
  const initialFormData = location.state.data || { country: "", zone: "", state: "", city: "" };
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({
    country: "",
    zone: "",
    state: "",
    city: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Clear the specific error when the user starts typing in the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const hasChanges = //find text fields update
    formData.country !== initialFormData.country ||
    formData.zone !== initialFormData.zone ||
    formData.state !== initialFormData.state ||
    formData.city !== initialFormData.city;

  const handleUpdate = async (e) => {
    e.preventDefault();

    let hasErrors = false;
    const newErrors = {};

    // Check for empty fields
    if (!formData.country) {
      newErrors.country = "Please enter a country.";
      hasErrors = true;
    }
    if (!formData.zone) {
      newErrors.zone = "Please enter a zone.";
      hasErrors = true;
    }
    if (!formData.state) {
      newErrors.state = "Please enter a state.";
      hasErrors = true;
    }
    if (!formData.city) {
      newErrors.city = "Please enter a city.";
      hasErrors = true;
    }

    if (hasErrors) {
      setErrors(newErrors);
      return; // Prevent further execution if any field is empty
    }

    setErrors({}); // Reset errors state

    const data = { ...formData, user_id: user_id };
    const response = await locationupdateApi(data); // Proceed with the update

    if (response.data.success) {
      Swal.fire({
        title: 'Success!',
        text: 'Location has been updated successfully',
        icon: 'success',
      });
    } else {
      Swal.fire({
        title: 'Warning',
        message: response.data.message,
        icon: 'warning',
      });
    }
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate('/admin/location');
  }

  useEffect(() => {
    // Clear errors when component mounts
    setErrors({});
  }, []);

  return (
    <>
      <div className="mb-4">
        <Card className="mt-4">
          <Card.Title className="card-header bg-gradient-header p-3 card-dash">
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <h6 className="mb-0 text-white">
                  <FontAwesomeIcon icon={faLocationDot} /> &nbsp;Location
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body className="card-body p-3">
            <Row className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Country</label>
                  <Input
                    type="text"
                    className={`form-control ${errors.country && 'is-invalid'}`}
                    placeholder="Country"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                  />
                  {errors.country && <div className="invalid-feedback">{errors.country}</div>}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">Zone</label>
                  <Input
                    type="text"
                    className={`form-control ${errors.zone && 'is-invalid'}`}
                    placeholder="Zone"
                    name="zone"
                    value={formData.zone}
                    onChange={handleInputChange}
                  />
                  {errors.zone && <div className="invalid-feedback">{errors.zone}</div>}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">State</label>
                  <Input
                    type="text"
                    className={`form-control ${errors.state && 'is-invalid'}`}
                    placeholder="State"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  />
                  {errors.state && <div className="invalid-feedback">{errors.state}</div>}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label className="form-control-label">City</label>
                  <Input
                    type="text"
                    className={`form-control ${errors.city && 'is-invalid'}`}
                    placeholder="City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                  {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                </div>
              </div>
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <a className="btn bg-gradient-primary p-2" onClick={handleBack}>
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </a>
            <button
              className="btn bg-gradient-primary p-2"
              onClick={handleUpdate}
              disabled={!hasChanges}
            >
              <FontAwesomeIcon icon={faArrowUp} /> Update
            </button>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Creation;
