import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../slice/authSlice";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import Captcha from "react-numeric-captcha";
import { Input } from "../includes/Inputs";
import {
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from "../slice/forgetSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { forgotPasswordApi } from "../api/apiPath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowRotateLeft} from "@fortawesome/free-solid-svg-icons";

function Login() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);

  const navigate = useNavigate();

  const handleCaptchaChange = (e) => {
    console.log(e.target.value);
    setCaptchaSuccess(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setValidEmail(true);
    setFormSubmitted(true);

    const emailRegex = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,})+$/;
    if (email === "") {
      setEmailError("Please enter your email");
      setValidEmail(false);
      setFormSubmitted(true);
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email");
      setValidEmail(false);
      setFormSubmitted(true);
      return;
    } else {
      setEmailError("");
      setValidEmail(true);
      setFormSubmitted(true);

      await forgotPasswordApi({ email: email })
        .then((response) => {
          if (response.data.success) {
            console.log("Form validated successfully");
            toast.success("Password reset link send to registered mail ID", {
              position: toast.POSITION.TOP_CENTER,
              className: "toast-success",
            });
            setTimeout(() => {
              navigate("/");
            }, 2000);
          } else {
            toast.error(
              "We are sorry! The given email address has not been registered with us. Please enter a registered email ID",
              {
                position: toast.POSITION.TOP_CENTER,
                className: "toast-error",
              }
            );
            setValidEmail(false);
            setFormSubmitted(true);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            className: "toast-error",
          });
        });
    }
  };
  const [captchaText, setCaptchaText] = useState(
    generateRandomNumericString(6)
  );
  const [userInput, setUserInput] = useState("");

  function generateRandomNumericString(length) {
    const characters = "0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  }

  const refreshCaptcha = () => {
    setCaptchaText(generateRandomNumericString(6)); // Adjust the length as needed
    setUserInput("");
  };

  const handleCaptcha = (event) => {
    event.preventDefault();

    if (userInput !== captchaText) {
      alert("Captcha incorrect. Please try again.");
      refreshCaptcha(); // Refresh the captcha on incorrect submission
    } else {
      alert("Captcha correct. Form submitted!");
      // Process the form submission here
    }
  };

  return (
    <main className="main-content mt-0">
      <section>
        <div className="page-header loginHeight">
          <Container>
            <ToastContainer />
            <Row>
              <Col lg={5} xl={4} md={6} className="d-flex flex-column mx-auto">
                <Card className="card-plain mt-5">
                  <Card.Header className="pb-0 text-left bg-transparent">
                    <h3 className="font-weight-bolder text-info text-gradient">
                      Forgot Password
                    </h3>
                  </Card.Header>
                  <Card.Body>
                    <Form onSubmit={handleSubmit}>
                      <label>Email</label>
                      <Input
                        type="text"
                        className={`form-control ${
                          formSubmitted
                            ? validEmail
                              ? "is-valid has-success"
                              : "is-invalid has-danger"
                            : ""
                        }`}
                        placeholder="Email"
                        label="Email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {emailError && formSubmitted && (
                        <span className="error">{emailError}</span>
                      )}
                      <div className="captchaAlign mt-2">
                        <div className="rnc">
                          
                            <Col className="d-flex rnc-canvas" sm={6} md={6} lg={6}>
                              <Input
                              className="rnc-canvas text-center"
                                id="captchaInput"
                                name="captchaInput"
                                value={captchaText}
                                onChange={(e) =>
                                  setUserInput(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                                disabled
                              />
                              <div className="rnc-column resbtn mt-2 ms-1" >
                                  <button className="rnc-button" onClick={refreshCaptcha}><FontAwesomeIcon icon={faArrowRotateLeft} /></button>
                                </div>
                            </Col>

                         
                          <div>
                              <Input
                              className="rnc-input mt-2"
                                type="text"
                                id="captchaInput"
                                name="captchaInput"
                                placeholder="Enter Captcha"
                                value={userInput}
                                onChange={(e) =>
                                  setUserInput(
                                    e.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                                
                                required
                              />
                          </div>
                        </div>
                        {/* <Captcha value="1235" onChange={handleCaptchaChange} /> */}
                      </div>
                      <div className="row text-center">
                        <div className="col-6">
                          <Link
                            to="/"
                            className="btn bg-gradient-primary w-100 mt-4 mb-0"
                          >
                            Back
                          </Link>
                        </div>
                        <div className="col-6">
                          <button
                            type="submit"
                            className="btn bg-gradient-primary w-100 mt-4 mb-0"
                            // disabled={!captchaSuccess}
                            onClick={handleCaptcha}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <div className="oblique position-absolute top-0 h-100 oblic-view d-none me-n8">
                  <div
                    className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                    style={{
                      backgroundImage:
                        'url("../assets/img/curved-images/curved11.jpg")',
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </main>
  );
}

export default Login;
