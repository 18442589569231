import React, { useState, useEffect } from "react";
import { Button, Container, Row, Card } from "react-bootstrap";
import { Check, Input, SelectInput } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRotateLeft,
  faArrowRight,
  faArrowLeft,
  faMotorcycle,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Grid from './Grid';
import Cookies from 'js-cookie';
import { vehicalDetailsListApi, addVehicalDetailsApi,updateVehicalDetailsApi } from "../../../api/apiPath";

const Creation = ({selectedRowData}) => {

  const [vehicleClass, setVehicleClass] = useState('');
  const [vehicleClassList, setVehicleClassList] = useState([]);
  const [vehicleClassError, setVehicleClassError] = useState('');
  const [typeOfBody, setTypeOfBody] = useState('');
  const [typeOfBodyList, setTypeOfBodyList] = useState([]);
  const [typeOfBodyError, setTypeOfBodyError] = useState('');
  const [nameOfManuf, setNameOfManuf] = useState('');
  const [nameOfManufList, setNameOfManufList] = useState([]);
  const [nameOfManufError, setNameOfManufError] = useState('');
  const [make, setMake] = useState('');
  const [makeList, setMakeList] = useState([]);
  const [makeError, setMakeError] = useState('');
  const [model, setModel] = useState('');
  const [modelList, setModelList] = useState([]);
  const [modelError, setModelError] = useState('');
  const [variant, setVariant] = useState('');
  const [variantList, setVariantList] = useState([]);
  const [variantError, setVariantError] = useState('');
  const [seatingCapacity, setSeatingCapacity] = useState('');
  const [seatingCapacityList, setSeatingCapacityList] = useState([]);
  const [seatingCapacityError, setSeatingCapacityError] = useState('');
  const [horsePower, setHorsePower] = useState('');
  const [horsePowerList, setHorsePowerList] = useState([]);
  const [horsePowerError, setHorsePowerError] = useState('');
  const [cubicCapacity, setCubicCapacity] = useState('');
  const [cubicCapacityError, setCubicCapacityError] = useState('');
  const [fuelUsed, setFuelUsed] = useState('');
  const [fuelUsedList, setFuelUsedList] = useState([]);
  const [fuelUsedError, setFuelUsedError] = useState('');
  const [regLadenWeight, setRegLadenWeight] = useState('');
  const [regLadenWeightError, setRegLadenWeightError] = useState('');
  const [unladenWeight, setUnladenWeight] = useState('');
  const [unladenWeightError, setUnladenWeightError] = useState('');
  const [userId, setUserId] = useState('');
  const [showGridList, setShowGridList] = useState(false);

  useEffect(() => {
    setUserId(Cookies.get("user_id"));
    
    fetchVehicalList();
    
  },[]);

  const [effectRunCount, setEffectRunCount] = useState(0);

  useEffect(() => {
    if (sessionStorage.getItem('Action') === "edit") {

      setVehicleClass(sessionStorage.getItem('class'));
      setTypeOfBody(sessionStorage.getItem('tb'));
      setNameOfManuf(sessionStorage.getItem('nm'));
      setMake(sessionStorage.getItem('make'));
      setModel(sessionStorage.getItem('model'));
      setVariant(sessionStorage.getItem('varient'));
      setSeatingCapacity(sessionStorage.getItem('sc'));
      setHorsePower(sessionStorage.getItem('hp'));
      setCubicCapacity(sessionStorage.getItem('cc'));
      setFuelUsed(sessionStorage.getItem('fu'));
      setRegLadenWeight(sessionStorage.getItem('rlw'));
      setUnladenWeight(sessionStorage.getItem('unlw'));
  
      setFrontImageUrl("");
      setFrontImage(false);
      setFrontImageUrl(sessionStorage.getItem('frontImage'));
      setFrontImage(true);
  
      setBackImageUrl('');
      setBackImage(false);
      setBackImageUrl(sessionStorage.getItem('backImage'));
      setBackImage(true);
  
      setRightImageUrl("");
      setRightImage(false);
      setRightImageUrl(sessionStorage.getItem('rightImage'));
      setRightImage(true);
  
      setLeftImageUrl("");
      setLeftImage(false);
      setLeftImageUrl(sessionStorage.getItem('leftImage'));
      setLeftImage(true);
    }
  },[sessionStorage.getItem('id')]);

  


  const fetchVehicalList = async () => {

    try {
      const response = await vehicalDetailsListApi({ loggedInuser_id: Cookies.get("user_id") });
      
      const responseData = response.data;

      setVehicleClassList(response.data["vehicleClass"].map(value => ({ value })));
      setTypeOfBodyList(response.data["typeOfBody"].map(value => ({ value })));
      setNameOfManufList(response.data["nameOfManufacturer"].map(value => ({ value })));
      setMakeList(response.data["make"].map(value => ({ value })));
      setModelList(response.data["model"].map(value => ({ value })));
      setVariantList(response.data["variant"].map(value => ({ value })));
      setSeatingCapacityList(response.data["seatingCapacity"].map(value => ({ value })));
      setHorsePowerList(response.data["horsePower"].map(value => ({ value })));
      setFuelUsedList(response.data["fuelUsed"].map(value => ({ value })));



      return responseData;
    } catch (error) {
      console.error("Error fetching vehicle list:", error);
      throw error;
    }
  };


    const handleVehicleUpdate = async () => {

      try {
        const formData = new FormData();
        formData.append('userid', userId);
        formData.append('class', vehicleClass);
        formData.append('type_of_body', typeOfBody);
        formData.append('name_of_manuf', nameOfManuf);
        formData.append('make', make);
        formData.append('model', model);
        formData.append('varient', variant);
        formData.append('seating_capacity', seatingCapacity);
        formData.append('hourse_power', horsePower);
        formData.append('cubic_capacity', cubicCapacity);
        formData.append('fuel_used', fuelUsed);
        formData.append('reg_laden_weight', regLadenWeight);
        formData.append('unladen_weight', unladenWeight);
        formData.append('front', frontImage);
        formData.append('back', backImage);
        formData.append('right', rightImage);
        formData.append('left', leftImage);
        formData.append('id', sessionStorage.getItem('id'));

   

    
        const response = await updateVehicalDetailsApi(formData);
    

        if(response.data.message === "Vehicle details updated successfully."){
          fetchVehicalList();
          setShowGridList(true);
          Swal.fire({
            title: "Success!",
            text: "Vehicle has been Updated successfully",
            icon: "success",
          });
          sessionStorage.clear();
          await handleReset();
        }else{
          Swal.fire({
            title: "Error!",
            text: response.data.message,
            icon: "danger",
          });
        }
        
        
      } catch (error) {
        console.error('Error uploading images:', error);
        Swal.fire({
          title: "Error!",
          text: error,
          icon: "danger",
        });
      }
    };



  const handleAddVehicle = async () => {

    try {
      const formData = new FormData();
      formData.append('user_id', userId);
      formData.append('class', vehicleClass);
      formData.append('type_of_body', typeOfBody);
      formData.append('name_of_manuf', nameOfManuf);
      formData.append('make', make);
      formData.append('model', model);
      formData.append('varient', variant);
      formData.append('seating_capacity', seatingCapacity);
      formData.append('hourse_power', horsePower);
      formData.append('cubic_capacity', cubicCapacity);
      formData.append('fuel_used', fuelUsed);
      formData.append('reg_laden_weight', regLadenWeight);
      formData.append('unladen_weight', unladenWeight);
      formData.append('front', frontImage);
      formData.append('back', backImage);
      formData.append('right', rightImage);
      formData.append('left', leftImage);
  
      const response = await addVehicalDetailsApi(formData);
  


      if(response.data.message === "Files and data stored."){
        fetchVehicalList();
        setShowGridList(true);
        Swal.fire({
          title: "Success!",
          text: "Vehicle has been added successfully",
          icon: "success",
        });
        sessionStorage.clear();
        await handleReset();
      }else{
        Swal.fire({
          title: "Error!",
          text: response.data.message,
          icon: "danger",
        });
      }
      
      
    } catch (error) {
      console.error('Error uploading images:', error);
      Swal.fire({
        title: "Error!",
        text: error,
        icon: "danger",
      });
    }
  };


  const [currentPage, setCurrentPage] = useState(1);
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [rightImage, setRightImage] = useState(null);
  const [leftImage, setLeftImage] = useState(null);

  const [frontImageUrl, setFrontImageUrl] = useState(null);
  const [backImageUrl, setBackImageUrl] = useState(null);
  const [rightImageUrl, setRightImageUrl] = useState(null);
  const [leftImageUrl, setLeftImageUrl] = useState(null);

  const [frontImageError, setFrontImageError] = useState('');
const [backImageError, setBackImageError] = useState('');
const [rightImageError, setRightImageError] = useState('');
const [leftImageError, setLeftImageError] = useState('');

const handleFileSelect = (e, field) => {
  const fileInput = e.target;
  const file = fileInput.files[0];

  // Check if a file is selected
  if (file) {
    const fileSizeInMB = file.size / (1024 * 1024); // Convert size to MB

    // Check if the file size exceeds 1MB
    if (fileSizeInMB > 1) {
      // Display an error message
      switch (field) {
        case "front":
          setFrontImageError("File size exceeds 1MB limit");
          setFrontImage(null);
          break;
        case "back":
          setBackImageError("File size exceeds 1MB limit");
          setBackImage(null);
          break;
        case "right":
          setRightImageError("File size exceeds 1MB limit");
          setRightImage(null);
          break;
        case "left":
          setLeftImageError("File size exceeds 1MB limit");
          setLeftImage(null);
          break;
        default:
          break;
      }

      // Reset the file input to clear the label
      fileInput.value = '';

      return; // Stop further processing
    }

    // Clear any previous error messages
    switch (field) {
      case "front":
        setFrontImageError("");
        break;
      case "back":
        setBackImageError("");
        break;
      case "right":
        setRightImageError("");
        break;
      case "left":
        setLeftImageError("");
        break;
      default:
        break;
    }

    // Proceed with setting the image URL
    const imageUrl = URL.createObjectURL(file);
    switch (field) {
      case "front":
        setFrontImageUrl(imageUrl);
        setFrontImage(file);
        break;
      case "back":
        setBackImageUrl(imageUrl);
        setBackImage(file);
        break;
      case "right":
        setRightImageUrl(imageUrl);
        setRightImage(file);
        break;
      case "left":
        setLeftImageUrl(imageUrl);
        setLeftImage(file);
        break;
      default:
        break;
    }
  }
};



  

  const handleDelete = (field) => {
    switch (field) {
      case "front":
        setFrontImage(null);
        break;
      case "back":
        setBackImage(null);
        break;
      case "right":
        setRightImage(null);
        break;
      case "left":
        setLeftImage(null);
        break;
      default:
        break;
    }
  };

  const handleVehicleClassChange = (value) => {
    setVehicleClass(value);

  
    // Validation: Max - 12 characters, only Alphabets and space
    if (value.length > 12) {
      setVehicleClassError('Max 12 characters allowed');
    } else if (!/^[a-zA-Z ]*$/.test(value)) {
      setVehicleClassError('Only Alphabets and space allowed');
    } else {
      setVehicleClassError('');
    }
  };

  const handleReset = () => {

    setVehicleClass('');
    setVehicleClassError('');
    
    setTypeOfBody('');
    setTypeOfBodyError('');
    
    setNameOfManuf('');
    setNameOfManufError('');
    
    setMake('');
    setMakeError('');
    
    setModel('');
    setModelError('');
    
    setVariant('');
    setVariantError('');
    
    setSeatingCapacity('');
    setSeatingCapacityError('');
    
    setHorsePower('');
    setHorsePowerError('');
    
    setCubicCapacity('');
    setCubicCapacityError('');
    
    setFuelUsed('');
    setFuelUsedError('');
    
    setRegLadenWeight('');
    setRegLadenWeightError('');
    
    setUnladenWeight('');
    setUnladenWeightError('');

    setCurrentPage(1);
        setFrontImage(false);
        setBackImage(false);
        setLeftImage(false);
        setRightImage(false);

        sessionStorage.clear();
    
  };
  
  const handleValidation = () => {
    // Check each field individually
    if (vehicleClass === '') {
      setVehicleClassError('Please enter a value for Vehicle Class');
      return false;
    }
  
    if (typeOfBody === '') {
      setTypeOfBodyError('Please enter a value for Type of Body');
      return false;
    }
  
    if (nameOfManuf === '') {
      setNameOfManufError('Please enter a value for Name of Manufacturer');
      return false;
    }
  
    if (make === '') {
      setMakeError('Please enter a value for Make');
      return false;
    }
  
    if (model === '') {
      setModelError('Please enter a value for Model');
      return false;
    }
  
    if (variant === '') {
      setVariantError('Please enter a value for Variant');
      return false;
    }
  
    if (seatingCapacity === '') {
      setSeatingCapacityError('Please enter a value for Seating Capacity');
      return false;
    }
  
    if (horsePower === '') {
      setHorsePowerError('Please enter a value for Horse Power');
      return false;
    }
  
    if (cubicCapacity === '') {
      setCubicCapacityError('Please enter a value for Cubic Capacity');
      return false;
    }
  
    if (fuelUsed === '') {
      setFuelUsedError('Please enter a value for Fuel Used');
      return false;
    }
  
    if (regLadenWeight === '') {
      setRegLadenWeightError('Please enter a value for Registered Laden Weight');
      return false;
    }
  
    if (unladenWeight === '') {
      setUnladenWeightError('Please enter a value for Unladen Weight');
      return false;
    }

    if (
      vehicleClassError !== '' ||
      typeOfBodyError !== '' ||
      nameOfManufError !== '' ||
      makeError !== '' ||
      modelError !== '' ||
      variantError !== '' ||
      seatingCapacityError !== '' ||
      horsePowerError !== '' ||
      cubicCapacityError !== '' ||
      fuelUsedError !== '' ||
      regLadenWeightError !== '' ||
      unladenWeightError !== ''
      
    ) {
      return false;
    }
  setCurrentPage(2);
    return true; 
  };
  
  
  

  return (
    <>
      <div
        style={{ display: currentPage === 1 ? "block" : "none" }}
        className="mb-4"
        id="page-1"
      >
        <Card class="mt-4">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                  <FontAwesomeIcon icon={faMotorcycle} /> &nbsp;Create Vehicle
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Class </label>
                  <span class="text-danger"> *</span>
                  <SelectInput
                    placeholder="Class"
                    datalistId="classList"
                    options={vehicleClassList}
                    value={vehicleClass}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      if (inputText.length < 12) {
                        setVehicleClass(inputText);
                      }
                      handleVehicleClassChange(inputText);
                    }}
                  />
                  <span className="text-danger">{vehicleClassError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Type of body </label>
                  <span class="text-danger"> *</span>
                  <SelectInput
                    placeholder="Type of body"
                    datalistId="bodyList"
                    options={typeOfBodyList}
                    value={typeOfBody}
                    onChange={(e) => {
                      const inputText = e.target.value;
                      
                      if (/^[a-zA-Z ]*$/.test(inputText)) {
                        if (inputText.length < 20) {
                          setTypeOfBody(inputText);
                          setTypeOfBodyError('');
                        } else {
                          setTypeOfBodyError('Max 20 characters allowed');
                        }
                      } else {
                        setTypeOfBodyError('Only Alphabets and space allowed');
                      }
                    }}
                  />
                  <span className="text-danger">{typeOfBodyError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Name of manufacturer</label>
                  <span class="text-danger"> *</span>
                  <SelectInput
                      placeholder="Name of manufacturer"
                      datalistId="manufacturerList"
                      options={nameOfManufList}
                      value={nameOfManuf}
                      onChange={(e) => {
                        const inputText = e.target.value;

                        if (/^[a-zA-Z ]*$/.test(inputText)) {
                          // Check for alphabets and spaces
                          if (inputText.length < 35) {
                            // Check for max length
                            setNameOfManuf(inputText);
                            setNameOfManufError('');
                          } else {
                            setNameOfManufError('Max 35 characters allowed');
                          }
                        } else {
                          setNameOfManufError('Only Alphabets and space allowed');
                        }
                      }}
                    />
                    <span className="text-danger">{nameOfManufError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Make</label>
                  <span class="text-danger"> *</span>
                  <SelectInput
                      placeholder="Make"
                      datalistId="makeList"
                      options={makeList}
                      value={make}
                      onChange={(e) => {
                        const inputText = e.target.value;

                        if (/^[a-zA-Z0-9 ]*$/.test(inputText)) {
                          // Check for alphabets, numeric, and spaces
                          if (inputText.length < 15) {
                            // Check for max length
                            setMake(inputText);
                            setMakeError('');
                          } else {
                            setMakeError('Max 15 characters allowed');
                          }
                        } else {
                          setMakeError('Only Alphabets, numeric and space allowed');
                        }
                      }}
                    />
                    <span className="text-danger">{makeError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Model</label>
                  <span class="text-danger"> *</span>
                  <SelectInput
                      placeholder="Model"
                      datalistId="modelList"
                      options={modelList}
                      value={model}
                      onChange={(e) => {
                        const inputText = e.target.value;

                        if (/^[a-zA-Z0-9\s]*$/.test(inputText)) {
                          // Check for alphabets, numeric, spaces, and special characters
                          if (inputText.length < 15) {
                            // Check for max length
                            setModel(inputText);
                            setModelError('');
                          } else {
                            setModelError('Max 15 characters allowed');
                          }
                        } else {
                          setModelError('Only Alphabets, space, numeric and special characters allowed');
                        }
                      }}
                    />
                    <span className="text-danger">{modelError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Variant</label>
                  <span class="text-danger"> *</span>
                  <SelectInput
                      placeholder="Variant"
                      datalistId="variantList"
                      options={variantList}
                      value={variant}
                      onChange={(e) => {
                        const inputText = e.target.value;

                        if (/^[a-zA-Z0-9\s]*$/.test(inputText)) {
                          // Check for alphabets, numeric, spaces, and special characters
                          if (inputText.length < 15) {
                            // Check for max length
                            setVariant(inputText);
                            setVariantError('');
                          } else {
                            setVariantError('Max 15 characters allowed');
                          }
                        } else {
                          setVariantError('Only Alphabets, space, numeric and special characters allowed');
                        }
                      }}
                    />
                    <span className="text-danger">{variantError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Seating capacity</label>
                  <span class="text-danger"> *</span>
                  <SelectInput
                      placeholder="Seating capacity"
                      datalistId="SeatingtList"
                      options={seatingCapacityList}
                      value={seatingCapacity}
                      onChange={(e) => {
                        const inputText = e.target.value;

                        if (/^\d*$/.test(inputText)) {
                          // Check for only numeric characters
                          if (inputText.length < 2) {
                            // Check for max length
                            setSeatingCapacity(inputText);
                            setSeatingCapacityError('');
                          } else {
                            setSeatingCapacityError('Max 2 characters allowed');
                          }
                        } else {
                          setSeatingCapacityError('Only Numeric without decimal and space allowed');
                        }
                      }}
                    />
                    <span className="text-danger">{seatingCapacityError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Horsepower/KW</label>
                  <span class="text-danger"> *</span>
                  <SelectInput
                      placeholder="Horsepower/KW"
                      datalistId="horsepowerList"
                      options={horsePowerList}
                      value={horsePower}
                      onChange={(e) => {
                        const inputText = e.target.value;

                        if (/^[a-zA-Z0-9 ]*$/.test(inputText)) {
                          // Check for only alphanumeric characters and space
                          if (inputText.length < 7) {
                            // Check for max length
                            setHorsePower(inputText);
                            setHorsePowerError('');
                          } else {
                            setHorsePowerError('Max 7 characters allowed');
                          }
                        } else {
                          setHorsePowerError('Only alphanumeric and space allowed');
                        }
                      }}
                    />
                    <span className="text-danger">{horsePowerError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Cubic Capacity </label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity"
                    value={cubicCapacity}
                    onChange={(e) => {
                      const inputText = e.target.value;

                      if (/^[a-zA-Z0-9 ]*$/.test(inputText)) {
                        // Check for only alphanumeric characters and space
                        if (inputText.length < 9) {
                          // Check for max length
                          setCubicCapacity(inputText);
                          setCubicCapacityError('');
                        } else {
                          setCubicCapacityError('Max 9 characters allowed');
                        }
                      } else {
                        setCubicCapacityError('Only alphanumeric and space allowed');
                      }
                    }}
                  />
                  <span className="text-danger">{cubicCapacityError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Fuel used</label>
                  <span class="text-danger"> *</span>
                  <SelectInput
                      placeholder="Fuel used"
                      datalistId="fuelList"
                      options={fuelUsedList}
                      value={fuelUsed}
                      onChange={(e) => {
                        const inputText = e.target.value;

                        if (/^[a-zA-Z \/]*$/.test(inputText)) {
                          // Check for only alphabets, space, and special character "/"
                          if (inputText.length < 15) {
                            // Check for max length
                            setFuelUsed(inputText);
                            setFuelUsedError('');
                          } else {
                            setFuelUsedError('Max 15 characters allowed');
                          }
                        } else {
                          setFuelUsedError('Only alphabets, slash (/) and spaces are allowed');
                        }
                      }}
                    />
                    <span className="text-danger">{fuelUsedError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                    Registered Laden Weight
                  </label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Registered Laden Weight "
                    value={regLadenWeight}
                    onChange={(e) => {
                      const inputText = e.target.value;

                      if (/^[0-9]*$/.test(inputText)) {
                        // Check for only numeric characters
                        if (inputText.length < 8) {
                          // Check for max length
                          setRegLadenWeight(inputText);
                          setRegLadenWeightError('');
                        } else {
                          setRegLadenWeightError('Max 8 characters allowed');
                        }
                      } else {
                        setRegLadenWeightError('Only Numeric without decimal and space allowed');
                      }
                    }}
                  />
                  <span className="text-danger">{regLadenWeightError}</span>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Unladen Weight</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Unladen Weight"
                    value={unladenWeight}
                    onChange={(e) => {
                      const inputText = e.target.value;

                      if (/^[0-9]*$/.test(inputText)) {
                        // Check for only numeric characters
                        if (inputText.length < 8) {
                          // Check for max length
                          setUnladenWeight(inputText);
                          setUnladenWeightError('');
                        } else {
                          setUnladenWeightError('Max 8 characters allowed');
                        }
                      } else {
                        setUnladenWeightError('Only Numeric without decimal and space allowed');
                      }
                    }}
                  />
                  <span className="text-danger">{unladenWeightError}</span>
                </div>
              </div>
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <a
              href="#"
              className="btn button-secondary-color p-2 "
              onClick={handleReset}
            >
              <FontAwesomeIcon icon={faRotateLeft} /> Reset
            </a>
            <a
              href="#"
              className="btn bg-gradient-primary p-2 "
              onClick={handleValidation}
            >
              <FontAwesomeIcon icon={faArrowRight} /> Next
            </a>
          </div>
        </Card>
      </div>

      <div
        style={{ display: currentPage === 2 ? "block" : "none" }}
        className="mb-4"
        id="page-2"
      >
        <Card class="mt-4">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                  <FontAwesomeIcon icon={faMotorcycle} /> &nbsp;Vehicle Images
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="form-control-label">
                    Front Image <span class="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Registered Laden Weight"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleFileSelect(e, "front")}
                  />
                  <span className="text-danger">{frontImageError}</span>
                  <div className="mt-4 text-center">
                    {frontImage && (
                      <div className="card text-center">
                        <img src={frontImageUrl} alt="Front" />
                        <Button
                          className="round-delete button-secondary-color"
                          style={{ position: "absolute", top: 0, right: 0 }}
                          onClick={() => handleDelete("front")}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            size="lg"
                            className="text-primary"
                          />
                        </Button>
                        <p class="form-control-label">Front Image</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="form-control-label">
                    Back Image <span class="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Registered Laden Weight"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleFileSelect(e, "back")}
                  />
                  <span className="text-danger">{backImageError}</span>
                  <div className="mt-4 text-center">
                    {backImage && (
                      <div className="card">
                        <img src={backImageUrl} alt="Back" />
                        <Button
                          className="round-delete button-secondary-color"
                          style={{ position: "absolute", top: 0, right: 0 }}
                          onClick={() => handleDelete("back")}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            size="lg"
                            className="text-primary"
                          />
                        </Button>
                        <p class="form-control-label">
                          Back Image <span class="text-danger">*</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="form-control-label">
                    Right Image <span class="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Registered Laden Weight"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleFileSelect(e, "right")}
                  />
                  <span className="text-danger">{rightImageError}</span>
                  <div className="mt-4 text-center">
                    {rightImage && (
                      <div className="card">
                        <img src={rightImageUrl} alt="Right" />
                        <Button
                          className="round-delete button-secondary-color"
                          style={{ position: "absolute", top: 0, right: 0 }}
                          onClick={() => handleDelete("right")}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            size="lg"
                            className="text-primary"
                          />
                        </Button>
                        <p class="form-control-label">Right Image</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <label class="form-control-label">
                    Left Image <span class="text-danger">*</span>
                  </label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Registered Laden Weight"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleFileSelect(e, "left")}
                  />
                  <span className="text-danger">{leftImageError}</span>
                  <div
                    className="mt-4 text-center"
                    style={{ position: "relative" }}
                  >
                    {leftImage && (
                      <div className="card">
                        <img src={leftImageUrl} alt="Left" />
                        <Button
                          className="round-delete button-secondary-color"
                          style={{ position: "absolute", top: 0, right: 0 }}
                          onClick={() => handleDelete("left")}
                        >
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            size="lg"
                            className="text-primary"
                          />
                        </Button>
                        <p class="form-control-label">Left Image</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <a
              href="#"
              className="btn button-secondary-color p-2 text-dark"
              onClick={() => setCurrentPage(1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </a>
            <a
              href="#"
              className="btn bg-gradient-primary p-2"
              onClick={handleAddVehicle}
              style={{ display: sessionStorage.getItem('Action') !== 'edit' ? 'block' : 'none' }}
            >
              <FontAwesomeIcon icon={faPlus} /> Add Vehicle
            </a>
            <a
              href="#"
              className="btn bg-gradient-primary p-2"
              onClick={handleVehicleUpdate}
              style={{ display: sessionStorage.getItem('Action') === 'edit' ? 'block' : 'none' }}
            >
              <FontAwesomeIcon icon={faPlus} /> Update
            </a>
          </div>
        </Card>
      </div>
      <Grid selectedRowData={selectedRowData} showGridList={showGridList} />
    </>
  );
};

export default Creation;
