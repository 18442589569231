import { configureStore } from "@reduxjs/toolkit";
import icgmsRegReducer from "./slice/icgmsRegSlice";
import authReducer from './slice/authSlice';
import forgotReducer from './slice/forgetSlice';

const store = configureStore({
  reducer: {
    form: icgmsRegReducer,
    auth: authReducer,
    forgotPassword: forgotReducer,
  },
});

export default store;