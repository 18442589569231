import React from "react";
import { Link } from 'react-router-dom';
import { Table, Badge, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Check, Input, Textarea } from "../../../includes/Inputs";
import Swal from 'sweetalert2';
import {
  faPenToSquare,
  faTrashCan,
  faListUl,
  faEye,
  faPlus,
  faFileExcel,
  faFilePdf,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";

const Grid = () => {
  return (
    <>
      <div className="card">
        <div class="card-header bg-gradient-header p-3 card-dash">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 d-flex align-items-center">
              <h6 class="mb-0 text-white">
                <FontAwesomeIcon icon={faListUl} />
                &nbsp; List of Users
              </h6>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 text-end">
              <a
                href=""
                className="btn button-secondary-color text-primary p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faFileExcel} /> Export XLS
              </a>
              <a
                href=""
                className="btn button-secondary-color text-primary  p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faFilePdf} /> PDF
              </a>
              <a
                href=""
                className="btn button-secondary-color text-primary  p-1 exportbtn me-2 mb-0"
              >
                <FontAwesomeIcon icon={faPrint} /> Print
              </a>
              <Link
                to="/admin/icgms/create"
                className="btn bg-gradient-primary p-2 mb-0 customBtn"
              >
                <FontAwesomeIcon icon={faPlus} /> Add New User
              </Link>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <Table className="table align-items-center mb-0">
            <thead>
              <tr>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Employee ID
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Employee ID"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center ">
                  Name
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Name"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Designation
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Designation"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center ">
                  Department
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Department"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Branch Name
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Branch Name"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center ">
                  Reporting Manager
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Reporting Manager"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center ">
                  Official Email
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Official Email"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Official Phone Number/Whatsapp number
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Official Phone Number/Whatsapp number"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder ">
                  Role
                  <Input
                    type="text"
                    className="form-control p-1"
                    placeholder="Role"
                  />
                </th>
                <th className="text-uppercase text-secondary text-xxs font-weight-bolder text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h6 className="ps-3 text-sm">CA-001</h6>
                </td>
                <td>
                  <h6 className="text-sm font-weight-bold mb-0 text-center">
                    John Michael
                  </h6>
                </td>
                <td>
                  <h6 className="text-sm font-weight-bold mb-0 text-center">
                    Finance & Accounts
                  </h6>
                </td>
                <td>
                  <h6 className="text-sm font-weight-bold mb-0 text-center">
                    Accounts
                  </h6>
                </td>
                <td>
                  <h6 className="text-sm font-weight-bold mb-0 text-center">
                    Chennai Branch
                  </h6>
                </td>
                <td>
                  <h6 className="text-sm font-weight-bold mb-0 text-center">
                    Vignesh/vign0004
                  </h6>
                </td>
                <td>
                  <h6 className="text-sm font-weight-bold mb-0 text-center">
                    john@icgms.com
                  </h6>
                </td>
                <td>
                  <h6 className="text-sm font-weight-bold mb-0 text-center">
                    9000090000
                  </h6>
                </td>
                <td>
                  <h6 className="text-sm font-weight-bold mb-0 text-center">
                    Finance & Accounts
                  </h6>
                </td>
                <td className="align-middle">
                  <div className="d-flex gap-3 justify-content-center">
                    <div>
                    <Link
                to="/admin/icgms/show"
                        className="font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="View"
                      >
                        <FontAwesomeIcon icon={faEye} size="sm" />
                        &nbsp;
                        <span className="d-none d-md-inline-block">View</span>
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/admin/icgms/edit"
                        className="font-weight-bold text-xs"
                        data-toggle="tooltip"
                        data-original-title="Edit user"
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="text-info"
                          size="sm"
                        />
                        &nbsp;
                        <span className="d-none d-md-inline-block">Edit</span>
                      </Link>
                    </div>
                    <div>
                    <Link
                        to="#!"
                        className="font-weight-bold text-xs"
                        onClick={(e) => {
                          e.preventDefault();
                          Swal.fire({
                            title: 'Enter your password',
                            input: 'password',
                            inputAttributes: {
                              autocapitalize: 'off'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'Confirm',
                            showLoaderOnConfirm: true,
                            preConfirm: (password) => {
                              console.log(`Entered password: ${password}`);
                            },
                            allowOutsideClick: () => !Swal.isLoading()
                          });
                        }}
                        data-toggle="tooltip"
                        data-original-title="Delete"
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          className="text-danger"
                          size="sm"
                        />
                        &nbsp;
                        <span className="d-none d-md-inline-block">Delete</span>
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Grid;
