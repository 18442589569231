import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Row, Card } from "react-bootstrap";
import img from "../../../resources/img/azure.png";
import Select from "react-select";
import { Table, Badge, Image } from "react-bootstrap";
import { Check, Input, Textarea, SelectInput } from "../../../includes/Inputs";
import Whatsapp from "../../../resources/img/whatsapp.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTrashCan,
  faRotateLeft,
  faBackward,
  faUserTie,
  faCheck,
  faFileSignature,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const Edit = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    Swal.fire({
      title: "Updated",
      text: "Client has been updated successfully",
      icon: "success",
      timer: 5000,
      timerProgressBar: true,
      willClose: () => {
        navigate("/admin/client");
      },
    });
  };
  const [selectedOption, setSelectedOption] = useState("0");

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };
  const [rows, setRows] = useState([
    {
      id: 1,
      name: "",
      designation: "",
      mobileNumber: "",
      email: "",
      default: false,
      isDelete: false,
    },
  ]);

  const addRow = (id) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, isDelete: true };
      }
      return row;
    });
    setRows([
      ...updatedRows,
      {
        id: rows.length + 1,
        name: "",
        designation: "",
        mobileNumber: "",
        email: "",
        default: false, // Ensure the newly added row has default as false initially
        isDelete: false,
      },
    ]);
  };
  const designation = [{ value: "tamilnadu", label: "Tamiladu" }];
  const deleteRow = (id) => {
    const newRows = rows.filter((row) => row.id !== id);
    setRows(newRows);
  };

  const handleInputChange = (e, id, field) => {
    const { value } = e.target;
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });
    setRows(updatedRows);
  };

  const handleRadioChange = (id) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, default: !row.default }; // Toggle the current row's default value
      }
      return { ...row, default: false }; // Reset other rows to non-default
    });
    setRows(updatedRows);
  };
  const [rows1, setRows1] = useState([
    {
      id: 1,
      name: "",
      designation: "",
      mobileNumber: "",
      email: "",
      default: false,
      isDelete: false,
    },
  ]);

  const addRow1 = (id) => {
    const updatedRows1 = rows1.map((row1) => {
      if (row1.id === id) {
        return { ...row1, isDelete: true };
      }
      return row1;
    });
    setRows1([
      ...updatedRows1,
      {
        id: rows1.length + 1,
        name: "",
        designation: "",
        mobileNumber: "",
        email: "",
        default: false, // Ensure the newly added row has default as false initially
        isDelete: false,
      },
    ]);
  };

  const deleteRow1 = (id) => {
    const newRows1 = rows1.filter((row1) => row1.id !== id);
    setRows1(newRows1);
  };

  const handleInputChange1 = (e, id, field) => {
    const { value } = e.target;
    const updatedRows1 = rows1.map((row1) => {
      if (row1.id === id) {
        return { ...row1, [field]: value };
      }
      return row1;
    });
    setRows1(updatedRows1);
  };

  const handleRadioChange1 = (id) => {
    const updatedRows1 = rows1.map((row1) => {
      if (row1.id === id) {
        return { ...row1, default: !row1.default }; // Toggle the current row's default value
      }
      return { ...row1, default: false }; // Reset other rows to non-default
    });
    setRows1(updatedRows1);
  };

  const [rows2, setRows2] = useState([
    {
      id: 2,
      name: "",
      designation: "",
      mobileNumber: "",
      email: "",
      default: false,
      isDelete: false,
    },
  ]);

  const addRow2 = (id) => {
    const updatedRows2 = rows2.map((row2) => {
      if (row2.id === id) {
        return { ...row2, isDelete: true };
      }
      return row2;
    });
    setRows2([
      ...updatedRows2,
      {
        id: rows2.length + 1,
        name: "",
        designation: "",
        mobileNumber: "",
        email: "",
        default: false, // Ensure the newly added row has default as false initially
        isDelete: false,
      },
    ]);
  };

  const deleteRow2 = (id) => {
    const newRows2 = rows2.filter((row2) => row2.id !== id);
    setRows2(newRows2);
  };

  const handleInputChange2 = (e, id, field) => {
    const { value } = e.target;
    const updatedRows2 = rows2.map((row2) => {
      if (row2.id === id) {
        return { ...row2, [field]: value };
      }
      return row2;
    });
    setRows2(updatedRows2);
  };

  const handleRadioChange2 = (id) => {
    const updatedRows2 = rows2.map((row2) => {
      if (row2.id === id) {
        return { ...row2, default: !row2.default }; // Toggle the current row's default value
      }
      return { ...row2, default: false }; // Reset other rows to non-default
    });
    setRows2(updatedRows2);
  };
  const zoneS = [
    { value: "east", label: "East" },
    { value: "north", label: "North" },
    { value: "south", label: "South" },
  ];
  const stateS = [
    { value: "tamilnadu", label: "Tamilnadu" },
    { value: "kerala", label: "Kerala" },
    { value: "karnataka", label: "Karnataka" },
  ];
  const cityS = [
    { value: "chennai", label: "Chennai" },
    { value: "coimbatore", label: "Coimbatore" },
    { value: "madurai", label: "Madurai" },
  ];
  const clientType = [
    { value: "broker", label: "Broker" },
    { value: "insurer", label: "Insurer" },
    { value: "agent", label: "Agent" },
    { value: "autodealer", label: "Auto dealer" },
    { value: "clientfinancier", label: "Client financier" },
    { value: "other", label: "Other" },
  ];
  return (
    <>
      <div class="mb-6 pb-6">
        <Card class="">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                  <FontAwesomeIcon icon={faUserTie} /> &nbsp;ICGMS-C00001
                </h6>
              </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                    Name <span class="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Client Name"
                    value="Vignesh"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Logo</label>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Client Logo"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Client Type</label>{" "}
                  <span class="text-danger">*</span>
                  <SelectInput
            placeholder="Select Product"
            datalistId="ProductList"
            options={[
              { value: "India" },
              { value: "New York" },
            ]}
          />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Registered Office</label>
                  <span class="text-danger">*</span>
                  <Textarea
                    type="type"
                    className="form-control"
                    placeholder="Registered Office"
                    value="Chennai"
                    rows="1"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                    Branch Name <span class="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Client Name"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                    Branch Code <span class="text-danger">*</span>
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Client Name"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">City</label>{" "}
                  <span class="text-danger">*</span>
                  <Select
                    label="Select an option"
                    id="userselection"
                    placeholder="Choose an option"
                    options={cityS}
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">State</label>{" "}
                  <span class="text-danger">*</span>
                  <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                readOnly
                              />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <div className="row">
                    <div className="col-6">
                      <label class="form-control-label mb-0 whatsapp">
                        Zone <span class="text-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6 text-end">
                      <Check
                        type="checkbox"
                        inline={true}
                        label=""
                        id="productuser"
                        name="whatsapp"
                        className="text-end ms-6 ps-6 pe-0 mb-0"
                      />
                    </div>
                  </div>
                  <input type="text"
                                className="form-control"
                                placeholder="Zone"
                                readOnly
                              />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Country</label>
                  <span class="text-danger">*</span>
                  <input
                            type="text"
                            className="form-control"
                            placeholder="Country"
                            readOnly
                          />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Zipcode/Pincode</label>
                  <span class="text-danger">*</span>
                  <Input
                    type="type"
                    className="form-control"
                    placeholder="Zipcode/Pincode"
                    value="600099"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                <div className="form-group mb-1">
                  <label className="form-control-label mb-0">
                    Geolocation <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Latitude"
                    value="211° 22´30"
                  />
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Longitude"
                    value="211° 22´30"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">GST Number</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="type"
                    className="form-control"
                    placeholder="22ASGDX7896I15"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">GST Copy</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="22ASGDX7896I15"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">PAN Number</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="type"
                    className="form-control"
                    placeholder="ASGDX7896I"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">PAN Copy</label>
                  <span class="text-danger"> *</span>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="ASGDX7896I"
                  />
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                    SLA / Vendor Agreement date
                  </label>
                  <span class="text-danger">*</span>
                  <Input
                    type="date"
                    className="form-control"
                    placeholder="SLA / Vendor Agreement date"
                    value="2028-07-22"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Copy of Agreement</label>
                  <span class="text-danger">*</span>
                  <Input
                    type="file"
                    className="form-control"
                    placeholder="Copy of Agreement"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Agreement valid from</label>
                  <span class="text-danger">*</span>
                  <Input
                    type="date"
                    className="form-control"
                    placeholder="Agreement valid from"
                    value="2020-07-22"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Agreement valid upto</label>
                  <span class="text-danger">*</span>
                  <Input
                    type="date"
                    className="form-control"
                    placeholder="Agreement valid upto"
                    value="2028-07-22"
                  />
                </div>
              </div>
              <Card.Title class="card-header bg-gradient-header p-3">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <h6 class="mb-0 text-white">
                      <FontAwesomeIcon icon={faLocationDot} /> &nbsp;Location
                      Information
                    </h6>
                  </div>
                </div>
              </Card.Title>
              <div className="table-responsive mt-2">
                <table className="table table-striped table-bordered text-center">
                  <thead>
                    <tr>
                      <td>
                        City <span class="text-danger">*</span>
                      </td>
                      <td>
                        State <span class="text-danger">*</span>
                      </td>
                      <td>
                        Zone <span class="text-danger">*</span>
                      </td>
                      <td>
                        Country <span class="text-danger">*</span>
                      </td>
                      <td>
                        Branch Name/Code <span class="text-danger">*</span>
                      </td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {rows1.map((row1) => (
                      <tr key={row1.id}>
                        <td>
                        <Select
                    label="Select an option"
                    id="userselection"
                    placeholder="Choose an option"
                    options={cityS}
                  />
                        </td>
                        <td>
                        <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                                readOnly
                                value={row1.state}
                                onChange={(e) =>
                                  handleInputChange1(e, row1.id, "state")
                                }
                              />
                        </td>
                        <td className="inline-block">
                          <div className="row">
                            <div className="col-9">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Zone"
                                readOnly
                                value={row1.zone}
                                onChange={(e) =>
                                  handleInputChange1(e, row1.id, "zone")
                                }
                              />
                            </div>
                            <div className="col-3 mt-2">
                              <Check
                                type="checkbox"
                                inline={true}
                                label=""
                                id="productuser"
                                name="whatsapp"
                                className="mb-0"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Country"
                            readOnly
                            value={row1.country}
                            onChange={(e) =>
                              handleInputChange1(e, row1.id, "country")
                            }
                          />
                        </td>
                        <td>
                        <SelectInput
            placeholder="Branch Name / Code"
            datalistId="branchlist"
            onChange={(e) =>
              handleInputChange1(e, row1.id, "branch")
            }
            options={[
              { value: "Admin" },
              { value: "User" },
            ]}
          />
                        </td>
                        <td className="align-middle">
                          {row1.isDelete ? (
                            <button
                              className="bg-transparent"
                              onClick={() => deleteRow1(row1.id)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className="text-danger"
                              />
                            </button>
                          ) : (
                            <button
                              className="bg-transparent text-primary"
                              onClick={() => addRow1(row1.id)}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <Card.Title class="card-header bg-gradient-header p-3">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <h6 class="mb-0 text-white">
                      <FontAwesomeIcon icon={faFileSignature} /> &nbsp;Contact
                      Information
                    </h6>
                  </div>
                </div>
              </Card.Title>
              <div className="table-responsive mt-2">
                <table className="table table-striped table-bordered text-center">
                  <thead>
                    <tr>
                      <td>Name <span class="text-danger">*</span></td>
                      <td>Designation <span class="text-danger">*</span></td>
                      <td>Mobile Number <span class="text-danger">*</span></td>
                      <td>Email ID <span class="text-danger">*</span></td>
                      <td>Default </td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row) => (
                      <tr key={row.id}>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value="Dinesh"
                            onChange={(e) =>
                              handleInputChange(e, row.id, "name")
                            }
                          />
                        </td>
                        <td>
                        <SelectInput
            placeholder="Designation"
            datalistId="designationList"
            options={[
              { value: "Admin" },
              { value: "User" },
            ]}
          />
                        </td>
                        <td className="inline-block">
                          <div className="row">
                            <div className="col-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Mobile Number"
                                value="9888898888"
                                onChange={(e) =>
                                  handleInputChange(e, row.id, "mobileNumber")
                                }
                              />
                            </div>
                            <div className="col-3 mt-2">
                              <Check
                                type="checkbox"
                                inline={true}
                                label=""
                                id="productuser"
                                name="whatsapp"
                                checked="true"
                                className="mb-0"
                              />
                              <img className="iconSize" src={Whatsapp} />
                            </div>
                          </div>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email ID"
                            value={row.email}
                            onChange={(e) =>
                              handleInputChange(e, row.id, "email")
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="radio"
                            checked={row.default}
                            onChange={() => handleRadioChange(row.id)}
                            className="mb-0 mt-3"
                          />
                        </td>
                        <td className="align-middle">
                          {row.isDelete ? (
                            <button
                              className="bg-transparent"
                              onClick={() => deleteRow(row.id)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className="text-danger"
                              />
                            </button>
                          ) : (
                            <button
                              className="bg-transparent text-primary"
                              onClick={() => addRow(row.id)}
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
                <Row>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-control-label">
                        Permitted Users
                        <span class="text-danger">*</span>
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Permitted Users"
                        value="10"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                    <div class="form-group">
                      <label class="form-control-label">
                        Advance paid in Rs
                      </label>
                      <span class="text-danger">*</span>
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Advance paid in Rs"
                        value="25000"
                      />
                    </div>
                  </div>
                </Row>
              <Card.Title class="card-header bg-gradient-header p-3">
                <div class="row">
                  <div class="col-6 d-flex align-items-center">
                    <h6 class="mb-0 text-white">
                      <FontAwesomeIcon icon={faFileSignature} /> &nbsp;Products
                      Offered
                    </h6>
                  </div>
                </div>
              </Card.Title>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Select Product </label>
                  <span class="text-danger">*</span>
                  <SelectInput
            placeholder="Select Product"
            datalistId="product1List"
            options={[
              { value: "City" },
              { value: "New York" },
            ]}
          />
                </div>
              </div>
              {/* {selectedOption === "0" && <></>} */}
              {selectedOption === "0" && (
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <td>Product Category</td>
                        <td>Product Name</td>
                        <td>Product Description</td>
                        <td>Per case price</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Car</td>
                        <td>Hyundai</td>
                        <td>i20</td>
                        <td>
                          <div class="input-group mb-3">
                            <span class="input-group-text">₹</span>
                            <Input
                              type="text"
                              class="form-control"
                              value="250"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </Row>
          </Card.Body>
          <div className="d-flex gap-3 justify-content-end pe-4 ">
            <Link
              to="#"
              onClick={handleClick}
              className="btn bg-gradient-primary p-2 "
            >
              <FontAwesomeIcon icon={faCheck} /> Update Client
            </Link>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Edit;
