import React, { useState,useEffect } from "react";
import { Button, Container, Row, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Check, Input } from "../../../includes/Inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faArrowLeft,
  faMotorcycle,
} from "@fortawesome/free-solid-svg-icons";

const Creation = () => {


  const [vehicleClass, setVehicleClass] = useState('');
  const [typeOfBody, setTypeOfBody] = useState('');
  const [nameOfManuf, setNameOfManuf] = useState('');
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [variant, setVariant] = useState('');
  const [seatingCapacity, setSeatingCapacity] = useState('');
  const [horsePower, setHorsePower] = useState('');
  const [cubicCapacity, setCubicCapacity] = useState('');
  const [fuelUsed, setFuelUsed] = useState('');
  const [regLadenWeight, setRegLadenWeight] = useState('');
  const [unladenWeight, setUnladenWeight] = useState('');
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [rightImage, setRightImage] = useState(null);
  const [leftImage, setLeftImage] = useState(null);

  const [frontImageUrl, setFrontImageUrl] = useState(null);
  const [backImageUrl, setBackImageUrl] = useState(null);
  const [rightImageUrl, setRightImageUrl] = useState(null);
  const [leftImageUrl, setLeftImageUrl] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {

      setVehicleClass(sessionStorage.getItem('class'));
      setTypeOfBody(sessionStorage.getItem('tb'));
      setNameOfManuf(sessionStorage.getItem('nm'));
      setMake(sessionStorage.getItem('make'));
      setModel(sessionStorage.getItem('model'));
      setVariant(sessionStorage.getItem('varient'));
      setSeatingCapacity(sessionStorage.getItem('sc'));
      setHorsePower(sessionStorage.getItem('hp'));
      setCubicCapacity(sessionStorage.getItem('cc'));
      setFuelUsed(sessionStorage.getItem('fu'));
      setRegLadenWeight(sessionStorage.getItem('rlw'));
      setUnladenWeight(sessionStorage.getItem('unlw'));
  
      setFrontImageUrl("");
      setFrontImage(false);
      setFrontImageUrl(sessionStorage.getItem('frontImage'));
      setFrontImage(true);
  
      setBackImageUrl('');
      setBackImage(false);
      setBackImageUrl(sessionStorage.getItem('backImage'));
      setBackImage(true);
  
      setRightImageUrl("");
      setRightImage(false);
      setRightImageUrl(sessionStorage.getItem('rightImage'));
      setRightImage(true);
  
      setLeftImageUrl("");
      setLeftImage(false);
      setLeftImageUrl(sessionStorage.getItem('leftImage'));
      setLeftImage(true);

  },[]);

  const handleEdit = () =>{
    sessionStorage.setItem("Action", "edit");
  }


  return (
    <>
    <div class="mb-4 ">
    <Card class="mt-4">
          <Card.Title class="card-header bg-gradient-header p-3 card-dash">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0 text-white">
                <FontAwesomeIcon icon={faMotorcycle} /> &nbsp;Vehicle
                </h6>
              </div>
          <div className="col-6 d-flex gap-3 justify-content-end pe-4 ">
          <a href="/admin/vehicle" className="btn bg-gradient-primary p-2 mb-0" onClick={handleEdit}>
            <FontAwesomeIcon icon={faPenToSquare} /> Edit
            </a>
            <a href="/admin/vehicle" className="btn bg-gradient-primary p-2 mb-0">
            <FontAwesomeIcon icon={faArrowLeft} /> Back
            </a>
          </div>
            </div>
          </Card.Title>
          <Card.Body class="card-body p-3">
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Class</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value={vehicleClass}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Type of body</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value={typeOfBody}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Name of manufacturer</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Name of manufacturer"
                    value={nameOfManuf}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Make</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Make"
                      value={make}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Model</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Model"

                      value={model}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Variant</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Variant"
                      value={variant}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Seating capacity</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Seating capacity"
                      value={seatingCapacity}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Horsepower/KW</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Horsepower/KW"
                      value={horsePower}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Cubic Capacity</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Cubic Capacity "
                    value={cubicCapacity}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Fuel used</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Fuel used"
                    value={fuelUsed}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">Registered Laden Weight</label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Registered Laden Weight"
                    value={regLadenWeight}
                    disabled="disabled"/>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <div class="form-group">
                  <label class="form-control-label">
                  Unladen Weight
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    placeholder="Unladen Weight"
                    value={unladenWeight}
                    disabled="disabled"/>
                </div>
              </div>
            </Row>
            <Row class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <div className="mt-4 text-center">
                    {frontImage && (
                      <div className="card text-center">
                        <img src={frontImageUrl} alt="Front" />
                        <p class="form-control-label">Front Image</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <div className="mt-4 text-center">
                    {backImage && (
                      <div className="card">
                        <img src={backImageUrl} alt="Back" />
                        <p class="form-control-label">
                          Back Image <span class="text-danger"></span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <div className="mt-4 text-center">
                    {rightImage && (
                      <div className="card">
                        <img src={rightImageUrl} alt="Right" />
                        <p class="form-control-label">Right Image</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-3">
                <div class="form-group">
                  <div
                    className="mt-4 text-center"
                    style={{ position: "relative" }}
                  >
                    {leftImage && (
                      <div className="card">
                        <img src={leftImageUrl} alt="Left" />
                        <p class="form-control-label">Left Image</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </Card.Body>
        </Card>
    </div>
    </>
  )
} 

export default Creation;
